import React from 'react';
import cn from 'classnames';
import { VALIDATION_ERROR } from 'endpoints/index';
import { useTranslation } from 'client/hooks';

import css from './Error.module.scss';

interface Props {
  className?: string;
  name: string;
  error: string;
}
const Error = ({ className = '', name, error }: Props) => {
  const { translate } = useTranslation();

  const errorText = () => {
    switch (error) {
      case VALIDATION_ERROR.REQUIRED:
        return translate(`errors.required.${name}`);
      case VALIDATION_ERROR.TOO_SHORT:
        return translate(`errors.tooShort`);
      case VALIDATION_ERROR.AT_LEAST_ONE_UPPER_LETTER:
        return translate(`errors.atLeastOneUpperLetter`);
      case VALIDATION_ERROR.AT_LEAST_ONE_NUMBER:
        return translate(`errors.atLeastOneNumber`);
      case VALIDATION_ERROR.AT_LEAST_ONE_LOWER_LETTER:
        return translate(`errors.atLeastOneLowerLetter`);
      case VALIDATION_ERROR.PASSWORDS_MISMATCH:
        return translate(`errors.passwordMissmatch`);
      case VALIDATION_ERROR.INVALID_URL:
        return translate(`errors.invalidUrl.${name}`);
      case VALIDATION_ERROR.INVALID_VALUE:
        return translate(`errors.invalidValue.${name}`);
      case VALIDATION_ERROR.INVALID_EMAIL:
        return translate(`errors.invalidEmail`);
      case VALIDATION_ERROR.MIN_TWO_WORDS:
        return translate(`errors.minTwoWords.${name}`);
      case VALIDATION_ERROR.MIN_CHARACTERS:
        return translate(`errors.minCharacters.${name}`);
      case VALIDATION_ERROR.MAX_CHARACTERS:
        return translate(`errors.maxCharacters.${name}`);
      case VALIDATION_ERROR.INVALID_CHARACTERS:
        return translate(`errors.invalidCharacters.${name}`);
      case VALIDATION_ERROR.INVALID_FORMAT:
        return translate(`errors.invalidFormat.${name}`);
      case VALIDATION_ERROR.INVALID_SIZE:
        return translate(`errors.invalidSize.${name}`);
      case VALIDATION_ERROR.TAXID_REQUIRED:
        return translate(`errors.required.taxId`);
      case VALIDATION_ERROR.TAXID_INVALID_VALUE:
        return translate(`errors.invalidValue.taxId`);
      case VALIDATION_ERROR.TAXCOUNTRY_REQUIRED:
        return translate(`errors.required.taxCountry`);
      case VALIDATION_ERROR.INVALID_PHONE_NUMBER:
        return translate(`errors.invalidPhone`);
      default:
        return error;
    }
  };
  return <p className={cn(css.error, className)}>{errorText()}</p>;
};

export default Error;
