import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { CONFIG } from 'constants/index';
import { MAIN_PAGE_ROUTE, USER_PROFILE_LIBRARY_ROUTE } from 'client/constants/routes';
import { useAppSelector, useTranslation } from 'client/hooks';
import { favoriteList as favoriteListApi } from 'client/redux/api/favorite-list';
import { selectAuth, selectUserName, selectUserGAStatus } from 'client/redux/user/selectors';
import { analytics } from 'client/utils/analytics';
import { Loader, ContentWrap } from 'client/components/common';
import { Item } from '../../Item';
import css from './Library.module.scss';

interface Props {
  className?: string;
}

export function Library(props: Props) {
  const { className = '' } = props;
  const userName = useAppSelector(selectUserName);
  const auth = useAppSelector(selectAuth);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const { translate: _t } = useTranslation('userProfile');
  const { translate: _tr } = useTranslation();
  const { data, isLoading } = favoriteListApi.endpoints.getFavoriteList.useQuery(undefined, { skip: !auth.isLoggedIn });
  const [deleteCompany, result] = favoriteListApi.endpoints.deleteFromFavoriteList.useMutation();
  const favoriteCompanies = React.useMemo(() => (data?.success ? data.data : []), [data]);

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Investor Profile - My Library`,
        page_location: `${CONFIG.domainUrl}${USER_PROFILE_LIBRARY_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onCompanyDeleteClick = (id: string) => {
    const text = _t('confirmRemovingLabel');

    if (confirm(text) == true) {
      deleteCompany({ companyId: id });
    }
  };

  const renderItems = () => {
    if (favoriteCompanies.length === 0) {
      return (
        <div className={css.noItems}>
          <h6 className={css.title}>{_tr('savingList.heading')}</h6>
          <p className={css.message}>{_tr('savingList.message')}</p>
          <Link to={MAIN_PAGE_ROUTE} className={css.button}>
            {_tr('savingList.homeBtn')}
          </Link>
        </div>
      );
    }

    return (
      <div className={cn(css.favoriteList, result.isLoading && css.isBusy)}>
        {userName && (
          <div className={css.title}>{_t('favoriteListTitle').replace('{name}', userName.split(' ')[0])}</div>
        )}
        <div className={css.items}>
          {favoriteCompanies.map((company) => (
            <Item data={company} key={company._id} onDeleteBtnClick={onCompanyDeleteClick} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={cn(css.investorProfile, className)}>
      <ContentWrap className={cn(css.content, favoriteCompanies.length === 0 && css.noData)}>
        {isLoading ? <Loader position="relative" /> : renderItems()}
      </ContentWrap>
    </div>
  );
}
