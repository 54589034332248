import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';
import cn from 'classnames';

import { ENDPOINT_ERROR } from 'endpoints/index';
import { useAppDispatch, useTranslation } from 'client/hooks';
import { emailSignUp } from 'client/redux/user/actions';
import { emailAndPassSignUpParamsSchema, Params } from 'api/auth/user/email-sign-up/params';
import { TextField } from 'client/components/common/form';
import { Loader } from 'client/components/common';

import TermsAndPolicy from '../../TermsAndPolicy';

import css from './EmailProvider.module.scss';

type FormType = Omit<Params, 'name'> & { firstName: string; lastName: string };

type Props = {
  onBackBtnClick: () => unknown;
  onSuccess: () => unknown;
};

const SignUp: React.FC<Props> = ({ onSuccess, onBackBtnClick }) => {
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState('');
  const { translate: _t } = useTranslation('popups');
  const formikContext = useFormik<FormType>({
    enableReinitialize: true,
    validationSchema: emailAndPassSignUpParamsSchema,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      setError('');

      const result = await dispatch(emailSignUp({ ...values, name: `${values.firstName} ${values.lastName}` }));

      if (result.meta.requestStatus === 'rejected') {
        setError(result.payload as string);
      } else {
        onSuccess();
      }
    },
  });

  return (
    <FormikProvider value={formikContext}>
      <h6 className={css.formTitle}>{_t('login.emailFormsTitles.signUp')}</h6>
      <Form className={cn(css.form, css.signUpForm, formikContext.isSubmitting && css.inactive)}>
        <div className={css.fields}>
          <div className={css.field}>
            <TextField name="firstName" autoComplete="first name" label={_t('login.emailFormsLabels.firstName')} />
          </div>
          <div className={css.field}>
            <TextField name="lastName" autoComplete="last name" label={_t('login.emailFormsLabels.lastName')} />
          </div>
          <div className={css.field}>
            <TextField name="email" autoComplete="email" label={_t('login.emailFormsLabels.email')} />
          </div>
          <div className={css.field}>
            <TextField
              name="password"
              type="password"
              label={() => (
                <>
                  {_t('login.emailFormsLabels.password')}{' '}
                  <span className={css.hint}>{_t('login.emailFormsLabels.passwordHint')}</span>
                </>
              )}
              autoComplete="new-password"
            />
          </div>
          <div className={css.field}>
            <TextField
              name="confirmPassword"
              type="password"
              label={_t('login.emailFormsLabels.confirmPassword')}
              autoComplete="new-password"
            />
          </div>
        </div>

        {error && (
          <div className={css.responseError}>
            {error === ENDPOINT_ERROR.ITEM_ALREADY_EXISTS ? `${_t(`login.errorLabels.userAlreadyExist`)}` : error}
          </div>
        )}

        <button type="submit" className={css.submitButton}>
          {_t('login.emailFormsButtons.signUp')}
        </button>

        {formikContext.isSubmitting && <Loader position="relative" className={css.loader} />}
      </Form>
      <div className={css.backButton} onClick={onBackBtnClick}>{`←${_t('login.emailFormsButtons.back')}`}</div>
      <TermsAndPolicy />
    </FormikProvider>
  );
};

export default SignUp;
