import { CONFIG } from 'constants/index';

export * as routes from './routes';

export enum CLIENT_ROLE {
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  ARTIST = 'ARTIST',
}

export const ANONYMOUS_UID = 'anonymous';

export const ERROR = {
  REQUIRED: 'Required',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  TOO_WEAK: 'Too weak',
  INVALID_FORMAT: 'Invalid format',
  INVALID_VALUE: 'Invalid value',
};

export const REG_EXP = {
  NUMBERS: /^[0-9\b]+$/,
  HEBREW_ONLY_LETTERS: /^[\u0590-\u05FF ,.'"-]+$/i,
  HEBREW_WITH_NUMBERS: /^[\u0590-\u05FF 0-9 ,.'"-]+$/,
  EMAIL: /[a-zA-Z@. 0-9]/,
  ENGLISH: /[a-zA-Z 0-9]/,
  WEBSITE: /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?(\/)?/g,
  NUMBERSPLUSMINUS: /^[0-9 ()+-]+$/,
};

export const STORAGE_FILES = {
  termsAndConditions: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/TERMS%20OF%20USE.pdf`,
  privacyPolicy: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/PRIVACY%20STATEMENT.pdf`,
  cyberCecurity: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/INTERENT%20SECURITY.pdf`,
  disclaimers: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/DISCLAIMER.pdf`,
};

export const INTERCOM_APP_ID = 'es58l7mm';
