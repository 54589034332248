import React from 'react';
import _ from 'lodash';
import { videoStatistics as videoStatisticsApi } from 'client/redux/api/video-statistics';
import { VideoJSPlayer } from 'client/components/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Swiper as SwiperType, Pagination } from 'swiper';

SwiperCore.use([EffectFade, Pagination]);

import css from './VideoSlider.module.scss';

type Props = {
  videos: string[];
};

const VideoSlider = ({ videos }: Props) => {
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperType | null>(null);
  const [updateVideoStatistics] = videoStatisticsApi.endpoints.updateVideoStatistics.useMutation();

  const onVideoCompleted = () => {
    if (swiperInstance) {
      if (swiperInstance.activeIndex === videos.length - 1) {
        swiperInstance.slideTo(0);
      } else {
        swiperInstance.slideNext();
      }
    }
  };

  const onStatisticUpdate = async (st: { fileName: string; progress: number }) => {
    const { fileName, progress } = st;
    await updateVideoStatistics({ fileName, progress });
  };

  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        effect={'fade'}
        allowTouchMove={false}
        noSwiping={false}
        pagination={{ el: '.custom-pagination', clickable: true }}
      >
        {_.map(videos, (video, index) => (
          <SwiperSlide key={`company_video_${video}`}>
            {({ isActive }) => {
              return (
                isActive && (
                  <div className={css.videoWrapper}>
                    <VideoJSPlayer
                      videoSRC={video}
                      onComplete={onVideoCompleted}
                      id={`company_video_${video}`}
                      onStatisticUpdate={(st) => onStatisticUpdate(st)}
                      isDefaultControls
                      isCenteredPlayBtn
                    />
                  </div>
                )
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="custom-pagination"></div>
    </>
  );
};

export default VideoSlider;
