import React from 'react';
import { IModel } from 'models/company/interface';

import { analytics } from 'client/utils/analytics';
import { Icon } from 'client/components/common';
import css from './CompanySocials.module.scss';

type Socials = IModel['socials'];

interface Props {
  socials: Socials;
}

const CompanySocials = ({ socials }: Props) => {
  const keys = Object.keys(socials) as Array<keyof Socials>;

  const onLinkClick = (platform: string, link: string) => {
    analytics.gtag.event('social_link', { Platform: platform, URL: link });
  };

  return (
    <div className={css.socials}>
      {keys.map((social) => {
        if (socials[`${social}`]) {
          switch (social) {
            case 'facebookUrl':
              return (
                <a
                  href={socials[`${social}`]}
                  key={social}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onLinkClick('facebook', socials[`${social}`])}
                >
                  <Icon type="facebook" className={css.icon} />
                </a>
              );
            case 'instagramUrl':
              return (
                <a
                  href={socials[`${social}`]}
                  key={social}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onLinkClick('instagram', socials[`${social}`])}
                >
                  <Icon type="instagram" className={css.icon} />
                </a>
              );
            case 'linkedInUrl':
              return (
                <a
                  href={socials[`${social}`]}
                  key={social}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onLinkClick('linkedIn', socials[`${social}`])}
                >
                  <Icon type="linkedin" className={css.icon} />
                </a>
              );
            case 'crunchbaseUrl':
              return (
                <a
                  href={socials[`${social}`]}
                  key={social}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onLinkClick('crunchbase', socials[`${social}`])}
                >
                  <Icon type="crunchbase" className={css.icon} />
                </a>
              );
            case 'tiktok':
              return (
                <a
                  href={socials[`${social}`]}
                  key={social}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onLinkClick(social, socials[`${social}`])}
                >
                  <Icon type="tiktok" className={css.icon} />
                </a>
              );
            case 'twitter':
              return (
                <a
                  href={socials[`${social}`]}
                  key={social}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => onLinkClick(social, socials[`${social}`])}
                >
                  <Icon type="twitter" className={css.icon} />
                </a>
              );
            default:
              return null;
          }
        }
      })}
    </div>
  );
};

export default CompanySocials;
