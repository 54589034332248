import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Result } from 'api/companies/public/paginate/params';
import { CONFIG } from 'constants/index';
import { MAIN_PAGE_ROUTE, ADD_YOUR_COMPANY_PAGE_ROUTE } from 'client/constants/routes';
import { analytics } from 'client/utils/analytics';
import { useAppSelector } from 'client/hooks/redux';
import { selectUserGAStatus } from 'client/redux/user/selectors';
import { companies as companiesApi } from 'client/redux/api/companies';
import { categories as categoriesApi } from 'client/redux/api/categories';
import { ScreenSlider } from 'client/components/common/mobile/ScreenSlider';
import { Company } from './Company';
import { Scroller } from 'client/utils/scroller';
import css from './Companies.module.scss';

interface Props {
  scroller: Scroller;
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
  active?: boolean;
  onDeactivate: () => unknown;
}

const FETCH_SIZE = 100;
const DEFAULT_COMPANIES: Result['items'] = [];

const Companies = (props: Props) => {
  const { scroller, active: isActive = false, className = '', onDeactivate } = props;
  const history = useHistory();
  const userGAStatus = useAppSelector(selectUserGAStatus);

  const urlParams = new URLSearchParams(history.location.search);
  const search = urlParams.get('search') || '';
  const category = urlParams.get('category') || '';

  // Fetch companies:
  const [offset, setOffset] = React.useState(0);
  const [paginate, { data: paginateRes, isFetching: isPaginateFetching, isLoading: isPaginateLoading }] =
    companiesApi.endpoints.paginateCompanies.useLazyQuery();
  const [
    paginateByCategories,
    {
      data: paginateByCategoriesRes,
      isFetching: isPaginateByCategoriesFetching,
      isLoading: isPaginateByCategriesLoading,
    },
  ] = companiesApi.endpoints.getCompanyWithCategoryOrder.useLazyQuery();

  const { data, isLoading: isCategoriesLoading } = categoriesApi.endpoints.getAllCategories.useQuery({
    isVisible: true,
  });
  const categories = data?.success ? data.data : [];

  React.useEffect(() => {
    setOffset(0);

    if (!category) {
      paginate({ search, offset, limit: FETCH_SIZE });
    } else {
      paginateByCategories({ search, filter: { categoryId: category }, offset, limit: FETCH_SIZE });
    }
  }, [search, category, offset, paginate, paginateByCategories]);

  React.useEffect(() => {
    const page = urlParams.get('page') || '';

    if (userGAStatus) {
      if (!category && !isCategoriesLoading && history.location.pathname === MAIN_PAGE_ROUTE) {
        analytics.gtag.event('page_view', {
          page_title: 'MainPage',
          page_location: page
            ? `${CONFIG.domainUrl}${MAIN_PAGE_ROUTE}?${urlParams}`
            : `${CONFIG.domainUrl}${MAIN_PAGE_ROUTE}`,
        });
      }

      if (category && !isCategoriesLoading) {
        const categoryData = categories?.find((item) => item._id.toString() === category);

        analytics.gtag.event('page_view', {
          page_title: `Category - ${categoryData?.name}`,
          page_location: `${CONFIG.domainUrl}${MAIN_PAGE_ROUTE}?${urlParams}`,
        });
      }
    }
  }, [history.location, isCategoriesLoading, userGAStatus]);

  const paginateCompanies = paginateRes?.success ? paginateRes.data.items : DEFAULT_COMPANIES;
  const paginateByCategoriesCompanies = paginateByCategoriesRes?.success
    ? paginateByCategoriesRes.data.items
    : DEFAULT_COMPANIES;
  const companies = category ? paginateByCategoriesCompanies : paginateCompanies;
  const paginateTotal = (paginateRes?.success ? paginateRes.data.page.total : 0) ?? 0;
  const paginateByCategoriesTotal =
    (paginateByCategoriesRes?.success ? paginateByCategoriesRes.data.page.total : 0) ?? 0;
  const total = category ? paginateByCategoriesTotal : paginateTotal;
  const isBusy = category
    ? isPaginateByCategoriesFetching || isPaginateByCategriesLoading
    : isPaginateFetching || isPaginateLoading;

  const page = Number(urlParams.get('page')) || 1;
  const onSliderPageChange = React.useCallback(
    (page: number) => {
      const urlParams = new URLSearchParams(history.location.search);

      analytics.gtag.event('MobileSwipe', {
        PageID: String(page),
      });

      urlParams.set('page', String(page));
      history.push(`${MAIN_PAGE_ROUTE}?${urlParams.toString()}`);
    },
    [history],
  );
  const onSliderDeactivate = React.useCallback(() => {
    const urlParams = new URLSearchParams(history.location.search);

    urlParams.delete('page');
    history.push(`${MAIN_PAGE_ROUTE}?${urlParams.toString()}`);

    onDeactivate();
  }, [history, onDeactivate]);

  const NoResults = React.useMemo(
    () =>
      function NoResults() {
        if (category && !search) {
          return (
            <div className={css.noResults}>
              <p className={css.noResultsText}>
                No companies are listed in this category yet, please check back later.
              </p>
            </div>
          );
        }

        return (
          <div className={css.noResults}>
            <div className={css.searchValue}>{`“${search.toUpperCase()}”`}</div>
            <p className={css.noResultsText}>
              Your search for <strong>{`“${search}”`}</strong> didn&apos;t return any results
            </p>
          </div>
        );
      },
    [category, search],
  );

  return (
    <ScreenSlider
      className={className}
      items={companies}
      page={page}
      total={total}
      scroller={scroller}
      active={isActive}
      busy={isBusy}
      destroyOnReachingTop
      containerRef={props.containerRef}
      slide={Company}
      noResults={NoResults}
      onPageChange={onSliderPageChange}
      onDeactivate={onSliderDeactivate}
    />
  );
};

export default Companies;
