import qs from 'qs';

import { ROUTE as GET_ROUTE, Params as GetParams, Result as GetResult } from 'api/main_page/public/get/params';
import {
  WEBSITE_ROUTE as GET_ALL_FAKE_SLIDES_ROUTE,
  Params as GetAllFakeSlidesParams,
  Result as GetAllFakeSlidesResult,
} from 'api/fake_mandates_slides/public/get-all/params';
import { ApiResponse } from 'src/types/api-response';

import api from '..';

export const main = api.injectEndpoints({
  endpoints: (builder) => ({
    getMainPageSliders: builder.query<ApiResponse<GetResult>, GetParams>({
      query: (params) => ({
        method: 'GET',
        url: `${GET_ROUTE}?${qs.stringify(params, { arrayFormat: 'comma' })}`,
      }),
      providesTags: (result) =>
        result?.success ? [{ type: 'MAIN_PAGE_SLIDERS', id: 'ALL' }] : [{ type: 'MAIN_PAGE_SLIDERS', id: 'ALL' }],
    }),
    getFakeSlides: builder.query<ApiResponse<GetAllFakeSlidesResult>, GetAllFakeSlidesParams>({
      query: (params) => ({
        method: 'GET',
        url: `${GET_ALL_FAKE_SLIDES_ROUTE}`,
      }),
      providesTags: (result) => [{ type: 'FAKE_MANDATES_SLIDES', id: 'ALL' }],
    }),
  }),
  overrideExisting: false,
});
