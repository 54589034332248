import _ from 'lodash';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppSelector, useTranslation } from 'client/hooks';
import { Company } from 'api/companies/public/paginate/params';
import { companies as companiesApi } from 'client/redux/api/companies';
import { category as categoryApi } from 'client/redux/api/category';
import { CATEGORY_PAGE_ROUTE } from 'client/constants/routes';
import { CONFIG } from 'constants/index';
import { CompanyVideoSlide, Button, Loader, HelmetComponent, AddYourCompanyBtnLink } from 'client/components/common';
import { imgixUrl } from 'client/utils/imgix-url';
import { analytics } from 'client/utils/analytics';
import { selectUserGAStatus } from 'client/redux/user/selectors';

import css from './Category.module.scss';

const ITEMS_PER_PAGE = 40;

const IMGIX_PARAMS = {
  bg: {
    'max-w': 1200,
    fit: 'fill',
    auto: 'format,compress',
    q: 80,
  },
  ogImage: {
    'max-w': 1200,
    height: 630,
    fit: 'fill',
    auto: 'format,compress',
    q: 70,
    crop: 'entropy',
  },
};

const Category: React.FC = () => {
  const history = useHistory();
  const { translate } = useTranslation();
  const [activeSlide, setActiveSlide] = React.useState<string>('');
  const [allItems, setAllItems] = React.useState<Company[]>([]);
  const [isShowMoreBtn, setIsShowMoreBtn] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const routeParams = useParams<{ id: string }>();
  const { data: categoryData, isFetching: isCategoryFetching } = categoryApi.endpoints.getCategory.useQuery({
    id: routeParams.id || '',
  });
  const categoryId = categoryData?.success ? categoryData.data?._id : undefined;
  const category = React.useMemo(() => (categoryData?.success ? categoryData.data : null), [categoryData]);
  const { data, isFetching, status } = companiesApi.endpoints.getCompanyWithCategoryOrder.useQuery(
    {
      filter: { categoryId },
      limit: ITEMS_PER_PAGE,
      offset: page * ITEMS_PER_PAGE,
    },
    { skip: !categoryId },
  );
  const userGAStatus = useAppSelector(selectUserGAStatus);

  const moreBtnRef = React.useRef<HTMLDivElement>(null);

  const clickOnViewMore = () => {
    setPage(page + 1);
  };

  React.useEffect(() => {
    if (category && userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Category - ${category.name}`,
        page_location: `${CONFIG.domainUrl}${CATEGORY_PAGE_ROUTE.replace(':id', category._id)}`,
      });
    }
  }, [category, userGAStatus]);

  React.useEffect(() => {
    setPage(0);
  }, [routeParams.id]);

  React.useEffect(() => {
    if (page === 0) {
      if (data?.success) {
        setAllItems([...data.data.items]);
      }
    } else {
      if (data?.success) {
        setAllItems([...allItems, ...data.data.items]);
      }
    }
  }, [data]);

  const scrollListener = () => {
    if (moreBtnRef && moreBtnRef.current) {
      if (window.scrollY + window.innerHeight > moreBtnRef.current?.offsetTop) {
        setIsShowMoreBtn(true);
      } else {
        setIsShowMoreBtn(false);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <>
      <HelmetComponent
        title={`${category?.metaTitle} - Catalyst Investors' Club`}
        description={`${category?.metaDescription}. Explore startups in the field of ${category?.name} on Catalyst Investors' Club`}
        ogTitle={`${category?.metaTitle}`}
        ogDescription={`${category?.metaDescription}. Explore startups in the field of ${category?.name} on Catalyst Investors' Club`}
        ogImage={`${imgixUrl(category?.shareImage, IMGIX_PARAMS.ogImage)}`}
      ></HelmetComponent>
      <div className={css.category}>
        {isCategoryFetching ? (
          <Loader position="relative" className={css.mainLoader} />
        ) : (
          <>
            {!isCategoryFetching && category && (
              <div className={css.intro}>
                <div className={css.info}>
                  <button type="button" className={css.backButton} onClick={() => history.goBack()}>
                    {'←Back'}
                  </button>
                  <h1 className={css.title}>{category?.name}</h1>
                  <div className={css.description}>{category.description}</div>
                </div>
                <div className={css.imageWrap}>
                  <img src={imgixUrl(category.shareImage, IMGIX_PARAMS.bg)} alt={category.metaDescription} />
                </div>
              </div>
            )}
            <div className={css.companies}>
              {_.map(allItems, (company, index) => (
                <div className={css.company} key={company._id}>
                  <CompanyVideoSlide
                    activeId={activeSlide}
                    data={company}
                    setActiveSlide={(id) => setActiveSlide(id)}
                    index={index + 1}
                    companiesLength={allItems.length}
                  />
                </div>
              ))}
            </div>
            {data?.success && allItems.length < data.data.page.total && (
              <div className={cn(css.viewMoreWrapper, isShowMoreBtn && css.show)} ref={moreBtnRef}>
                <Button onClick={clickOnViewMore} className={css.viewMoreBtn}>
                  {isFetching && <Loader position="absolute" className={css.companiesLoader} />}
                  <span>{translate('commonButtons.loadMore')}</span>
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      <AddYourCompanyBtnLink from={CATEGORY_PAGE_ROUTE.replace(':id', routeParams.id)} />
    </>
  );
};

export default Category;
