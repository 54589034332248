import React from 'react';
import { useHistory } from 'react-router';

import { CONFIG } from 'constants/index';
import { ControlPanel, Header } from 'client/components/common/mobile';
import { useAppSelector, useResponsive, useTranslation } from 'client/hooks';
import { selectUserGAStatus } from 'client/redux/user/selectors';
import { analytics } from 'client/utils/analytics';
import FormWrapper from './Form';
import { HelmetComponent } from 'client/components/common';

import { ADD_YOUR_COMPANY_THANKS_PAGE_ROUTE, ADD_YOUR_COMPANY_PAGE_ROUTE } from 'client/constants/routes';

import css from './Form.module.scss';

const Form = () => {
  const { translate } = useTranslation();
  const [isMobile] = useResponsive(['MOBILE']);
  const history = useHistory();
  const userGAStatus = useAppSelector(selectUserGAStatus);

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Add your company`,
        page_location: `${CONFIG.domainUrl}${ADD_YOUR_COMPANY_PAGE_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onSubmitSuccess = () => {
    analytics.gtag.event('Add_Your_Company_Submission', { formType: 'Add_Your_Company' });
    history.push(ADD_YOUR_COMPANY_THANKS_PAGE_ROUTE);
  };

  return (
    <>
      <HelmetComponent
        title="Add your company - Catalyst Investors' Club"
        description="Are you a startup founder? Add your company to Catalyst Investors' Club and get access to reputable lead investors."
        ogTitle="Add your company to Catalyst Investors' Club"
        ogDescription="Are you a startup founder? Add your company to Catalyst Investors' Club and get access to reputable lead investors."
      ></HelmetComponent>
      <div className={css.claimCompanyForm}>
        {isMobile ? <Header className={css.pageHeader} /> : null}
        {isMobile ? <ControlPanel className={css.controlPanel} /> : null}
        <div className={css.info}>
          <div className={css.infoWrapper}>
            <div className={css.title}>{translate('claimCompany.form.title')}</div>
            {isMobile && (
              <div className={css.videoBlockMobile}>
                <div className={css.video}>
                  <iframe
                    src="https://player.vimeo.com/video/805491225?h=b8fbb4cdae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    title="CIC"
                    data-ready="true"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            )}
            <FormWrapper onSubmitSuccess={onSubmitSuccess} />
          </div>
        </div>
        {!isMobile && (
          <div className={css.videoBlock}>
            <div className={css.video}>
              <iframe
                src="https://player.vimeo.com/video/805491225?h=b8fbb4cdae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                title="CIC"
                data-ready="true"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        )}
      </div>
    </>
  );
};

export default Form;
