import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'client/hooks';

import Icon from '../Icon';

import {
  CONTACT_US_PAGE_ROUTE,
  FAQ_PAGE_ROUTE,
  ADD_YOUR_COMPANY_PAGE_ROUTE,
  MAIN_PAGE_ROUTE,
  TERMS_OF_USE_PAGE_ROUTE,
  PRIVACY_POLICY_PAGE_ROUTE,
  DISCLAIMER_PAGE_ROUTE,
  CYBER_SECURITY_PAGE_ROUTE,
  ACCESSIBILITY_STATETMENT_PAGE_ROUTE,
} from 'client/constants/routes';

import logo from 'client/assets/header/cic-logo-v2.svg';
import css from './Footer.module.scss';

const MENU_LINKS = [
  // { name: 'about', link: ABOUT_PAGE_ROUTE },
  { name: 'faq', link: FAQ_PAGE_ROUTE },
  { name: 'addYourCompany', link: ADD_YOUR_COMPANY_PAGE_ROUTE },
  { name: 'contactUs', link: CONTACT_US_PAGE_ROUTE },
];

const STATIC_PAGES = [
  {
    name: 'termsAndConditions',
    link: TERMS_OF_USE_PAGE_ROUTE,
  },
  {
    name: 'accessibilityStatetment',
    link: ACCESSIBILITY_STATETMENT_PAGE_ROUTE,
  },
  {
    name: 'privacyPolicy',
    link: PRIVACY_POLICY_PAGE_ROUTE,
  },
  {
    name: 'disclaimers',
    link: DISCLAIMER_PAGE_ROUTE,
  },
  {
    name: 'cyberCecurity',
    link: CYBER_SECURITY_PAGE_ROUTE,
  },
];

const SOCIALS_LINKS = {
  linkedin: 'https://www.linkedin.com/company/catalyst-investors-club/',
  facebook: 'https://www.facebook.com/',
};

const Footer = () => {
  const { translate } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className={css.footerInner}>
        <div className={css.leftPart}>
          <div className={css.logoWrapper}>
            <Link to={MAIN_PAGE_ROUTE} className={css.logo}>
              <img src={logo} alt="logo" />
            </Link>
            {/* <div className={css.divider}></div>
            <div className={css.logoText}>{translate('header.logoText')}</div> */}
          </div>
          <div
            className={css.copyrightText}
            dangerouslySetInnerHTML={{ __html: translate('footer.copyRightText').replace('{year}', `${year}`) }}
          ></div>
        </div>
        <div className={css.menu}>
          <div className={css.links}>
            {MENU_LINKS.map((link) => {
              if (link.name === 'contactUs') {
                return (
                  <a href="mailto:Support@catalyst-ic.com" key={`${link.link}`} className={css.navLink}>
                    <span>{translate(`footer.menuItems.${link.name}`)}</span>
                  </a>
                );
              }
              return (
                <Link to={link.link} key={`${link.link}`} className={cn(css.navLink)}>
                  <span>{translate(`footer.menuItems.${link.name}`)}</span>
                </Link>
              );
            })}
          </div>
          <div className={css.docs}>
            {STATIC_PAGES.map((link) => {
              return (
                <Link to={link.link} key={link.name} className={cn(css.docLink)}>
                  <span>{translate(`footer.menuItems.${link.name}`)}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className={css.footerSocials}>
          <a href={SOCIALS_LINKS.linkedin} target="_blank" rel="noreferrer">
            <Icon type="linkedin" />
          </a>
          {/* <a href={SOCIALS_LINKS.facebook} target="_blank" rel="noreferrer">
            <Icon type="facebook" />
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
