import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { useAppSelector, useAppDispatch } from 'client/hooks/redux';
import { selectAuth, selectUserRole } from 'client/redux/user/selectors';
import { setMenuState, setPopup } from 'client/redux/ui';
import { selectMenuState } from 'client/redux/ui/selectors';
import { MANDATES_PAGE_ROUTE } from 'client/constants/routes';
import { analytics } from 'client/utils/analytics';
import logoIconUrl from 'client/assets/header/logo1.png';
import logoTextUrl from 'client/assets/header/logo2.png';
import homeIconUrl from './home.svg';
import categoriesIconUrl from './list.svg';
import savedListIconUrl from './saved.svg';
import profileIconUrl from './user.svg';

import css from './Footer.module.scss';

interface Props {
  className?: string;
  hidden?: boolean;
}

const Footer = (props: Props) => {
  const { hidden, className = '' } = props;
  const auth = useAppSelector(selectAuth);
  const controlPanelState = useAppSelector(selectMenuState);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isCategoriesActive = controlPanelState === 'categories';
  const userRole = useAppSelector(selectUserRole);
  const isInvestor = userRole && userRole !== 'VIEWER';

  const navigateTo = (pathname: string) => {
    if (isCategoriesActive) {
      dispatch(setMenuState('hidden'));
    }

    history.push(pathname);
  };

  const renderMainBtn = () => {
    if (isInvestor) {
      return 'INVEST NOW';
    }
    if (auth.isLoggedIn) {
      return (
        <div className={css.textLogo}>
          <img src={logoTextUrl} />
          <p>{`Investors' Club`}</p>
        </div>
      );
    }
    return 'Join the club';
  };

  const buttons = [
    {
      icon: homeIconUrl,
      label: 'Home',
      onClick: () => {
        analytics.gtag.event('mobile_footer', { item: 'Home' });
        navigateTo(routes.MAIN_PAGE_ROUTE);
      },
      checkActiveState: () => false,
    },
    {
      icon: categoriesIconUrl,
      label: 'Categories',
      onClick: () => {
        analytics.gtag.event('mobile_footer', { item: 'Categories' });
        dispatch(setMenuState('categories'));
      },
      checkActiveState: () => isCategoriesActive,
    },
    {
      icon: logoIconUrl,
      label: renderMainBtn(),
      onClick: () => {
        if (isInvestor) {
          history.push(MANDATES_PAGE_ROUTE + '?page=1');
          return;
        }
        if (!auth.isLoggedIn) {
          dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
          return;
        }
        if (auth.isLoggedIn && userRole === 'VIEWER') {
          dispatch(
            setPopup({ type: 'join-as-investor', data: { link: history.location.pathname, isExternalLink: false } }),
          );
          return;
        }
      },
      checkActiveState: () => false,
    },
    {
      icon: savedListIconUrl,
      label: 'Saved',
      onClick: () => {
        analytics.gtag.event('mobile_footer', { item: 'Saved' });
        if (auth.isLoggedIn) navigateTo(routes.SAVING_LIST_PAGE_ROUTE);
        else dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      },
      checkActiveState: () => !isCategoriesActive && history.location.pathname === routes.SAVING_LIST_PAGE_ROUTE,
    },
    {
      icon: profileIconUrl,
      label: 'Profile',
      onClick: () => {
        analytics.gtag.event('mobile_footer', { item: 'Profile' });
        if (auth.isLoggedIn) navigateTo(routes.USER_PROFILE_ROUTE);
        else dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      },
      checkActiveState: () => !isCategoriesActive && history.location.pathname === routes.USER_PROFILE_ROUTE,
    },
  ];

  return (
    <div className={cn(css.footer, hidden && css.hidden, className)}>
      {buttons.map((button, index) => {
        const isActive = button.checkActiveState();
        return (
          <button
            className={cn(css.footerBtn, { [css.active]: isActive }, isInvestor && css.forInvestor)}
            onClick={isActive ? undefined : button.onClick}
            type="button"
            key={`${button.label}-${index}`}
          >
            <div className={css.footerBtnIcon}>
              <img src={button.icon} alt={typeof button.label === 'string' ? button.label : ''} />
            </div>
            <div className={css.footerBtnLabel}>{button.label}</div>
          </button>
        );
      })}
    </div>
  );
};

export default Footer;
