import React from 'react';
import cn from 'classnames';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { State as AuthState, useAuthDispatch } from '../../state';
import css from './EmailProvider.module.scss';

type Props = {
  action: AuthState['authType'];
  onSuccess: (email?: string) => unknown;
  onBackClick: () => unknown;
};
const EmailProvider: React.FC<Props> = ({ action, onBackClick, onSuccess }) => {
  const authDispatch = useAuthDispatch();

  const onForgotBackBtnClick = () => {
    authDispatch({ type: 'setAuthType', payload: 'sign-in' });
  };

  const onForgotBtnClick = () => {
    authDispatch({ type: 'setAuthType', payload: 'forgot-password' });
  };

  return (
    <div className={cn(css.emailProvider, css[action])}>
      {action === 'sign-up' && <SignUp onBackBtnClick={onBackClick} onSuccess={onSuccess} />}
      {action === 'sign-in' && (
        <SignIn onBackBtnClick={onBackClick} onSuccess={onSuccess} onForgotClick={onForgotBtnClick} />
      )}
      {action === 'forgot-password' && <ForgotPassword onSuccess={onSuccess} onBackClick={onForgotBackBtnClick} />}

      {action === 'restore-password' && <ResetPassword onSuccess={onSuccess} />}
    </div>
  );
};

export default EmailProvider;
