import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import DOMPurify from 'dompurify';

import { useHistory } from 'react-router-dom';
import { useTranslation, useOnClickOutside } from 'client/hooks';
import { analytics } from 'client/utils/analytics';

import { SEARCH_RESULTS_PAGE_ROUTE } from 'client/constants/routes';

import Icon from '../Icon';
import css from './Search.module.scss';

const Search = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isShowSearchText, setIsShowSearchText] = React.useState<boolean>(true);
  const [searchString, setSearchString] = React.useState<string>('');
  const searchRef = React.useRef<HTMLDivElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const { translate } = useTranslation();
  const history = useHistory();

  const handleClickOutside = () => {
    setIsOpen(false);
    if (searchInputRef.current) searchInputRef.current.value = '';
    if (searchString) setSearchString('');
    _.delay(() => {
      setIsShowSearchText(true);
    }, 250);
  };

  const onSearchBtnClick = () => {
    if (!isOpen) {
      setIsOpen(true);
      setIsShowSearchText(false);
      searchInputRef.current && searchInputRef.current.focus();
    } else {
      if (!searchInputRef.current?.value) return;

      if (!DOMPurify.sanitize(searchInputRef.current.value)) return;

      analytics.gtag.event('search', {
        search_term: `${searchInputRef.current.value}`,
      });

      history.push(`${SEARCH_RESULTS_PAGE_ROUTE}?search=${searchInputRef.current.value}`);
      // if (searchInputRef.current?.value) searchInputRef.current.value = '';

      // setIsOpen(false);
      // if (searchInputRef.current) searchInputRef.current.value = '';
      // _.delay(() => {
      //   setIsShowSearchText(true);
      // }, 250);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchInputRef.current?.value) {
      onSearchBtnClick();
    }
  };

  const clickOnClear = () => {
    if (searchInputRef.current) searchInputRef.current.value = '';
    setSearchString('');
    searchInputRef.current && searchInputRef.current.focus();
  };

  useOnClickOutside<HTMLDivElement>(searchRef, handleClickOutside);

  return (
    <div ref={searchRef} className={cn(css.searchWrapper, isOpen && css.active)}>
      <form action="" onSubmit={onSubmit}>
        <div className={css.inputWrapper}>
          <input
            ref={searchInputRef}
            type="text"
            placeholder={translate('search.placeholder')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value)}
          />
          {searchString && (
            <div className={css.clearBtn} onClick={clickOnClear}>
              <div className={css.filter}></div>
            </div>
          )}
        </div>
      </form>
      <div className={css.searchBtn} onClick={onSearchBtnClick}>
        <div className={css.searchIcon}>
          <Icon type="search" />
        </div>
        {isShowSearchText && <p className={css.btnText}>{translate('search.placeholder')}</p>}
      </div>

      <div className={css.divider}></div>
    </div>
  );
};

export default Search;
