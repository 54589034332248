export const ROOT = '';
export const MAIN_PAGE_ROUTE = `${ROOT}/`;
export const SIGN_IN_ROUTE = `${ROOT}/sign-in`;
export const SIGN_UP_ROUTE = `${ROOT}/sign-up`;
export const COMPANY_PAGE_ROUTE = `${ROOT}/company/:id`;
export const MANDATE_PAGE_ROUTE = `${ROOT}/mandate/:id`;
export const WELCOME_PAGE_ROUTE = `${ROOT}/welcome`;
export const MANDATES_PAGE_ROUTE = `${ROOT}/mandates`;
export const FAQ_PAGE_ROUTE = `${ROOT}/faq`;
export const ABOUT_PAGE_ROUTE = `${ROOT}/about`;
export const INVESTORS_CLUB_PAGE_ROUTE = `${ROOT}/investors-club`;
export const ADD_YOUR_COMPANY_PAGE_ROUTE = `${ROOT}/add-your-company`;
export const ADD_YOUR_COMPANY_THANKS_PAGE_ROUTE = `${ROOT}/add-your-company/thank-you`;
export const CONTACT_US_PAGE_ROUTE = `${ROOT}/contact-us`;
export const USER_PROFILE_ROUTE = `${ROOT}/profile`;
export const USER_PROFILE_INVESTOR_ROUTE = `${ROOT}/profile/investor`;
export const USER_PROFILE_INVESTMENTS_ROUTE = `${ROOT}/profile/investments`;
export const USER_PROFILE_LIBRARY_ROUTE = `${ROOT}/profile/library`;
export const CATEGORY_PAGE_ROUTE = `${ROOT}/category/:id`;
export const SEARCH_RESULTS_PAGE_ROUTE = `${ROOT}/search-results`;
export const SAVING_LIST_PAGE_ROUTE = `${ROOT}/saving-list`;
export const NOT_FOUND_PAGE_ROUTE = `${ROOT}/not-found`;
export const TERMS_OF_USE_PAGE_ROUTE = `${ROOT}/terms-of-use`;
export const PRIVACY_POLICY_PAGE_ROUTE = `${ROOT}/privacy-policy`;
export const DISCLAIMER_PAGE_ROUTE = `${ROOT}/disclaimer`;
export const CYBER_SECURITY_PAGE_ROUTE = `${ROOT}/cyber-security`;
export const CONSENT_FORM_PAGE_ROUTE = `${ROOT}/consent-form`;
export const ACCESSIBILITY_STATETMENT_PAGE_ROUTE = `${ROOT}/accessibility-statement`;
