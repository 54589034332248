import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { CONFIG } from 'constants/index';
import { DS_GET_CLIENT_ENVELOPE_DOCS } from 'api/routes';
import { USER_PROFILE_INVESTOR_ROUTE } from 'client/constants/routes';
import { useAppDispatch, useAppSelector, useTranslation, useResponsive } from 'client/hooks';
import { Button, ContentWrap, Icon } from 'client/components/common';
import { selectUserOnboarding, selectUserGAStatus } from 'client/redux/user/selectors';
import { getProgress } from 'utils/onboarding/progress';
import { analytics } from 'client/utils/analytics';
import { setPopup } from 'client/redux/ui';

import css from './InvestorProfile.module.scss';

interface Props {
  className?: string;
}

export function InvestorProfile(props: Props) {
  const { className = '' } = props;
  const onboarding = useAppSelector(selectUserOnboarding);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const dispatch = useAppDispatch();
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate: _t } = useTranslation('userProfile');
  const allProgress = getProgress(onboarding);

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Investor Profile - Onboarding`,
        page_location: `${CONFIG.domainUrl}${USER_PROFILE_INVESTOR_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onboardingSectionData = new SectionData(
    {
      title: _t('investor.investorProfileOnboardingTitle'),
      subTitle: _t('investor.investorProfileOnboardingSubTitle'),
      stages: [
        {
          name: 'personalDetails',
          progress: allProgress.contactDetails,
          isEditable: true,
        },
        {
          name: 'questionnaire',
          progress: (allProgress.questionnaire + allProgress.personalDetails + allProgress.nda) / 3,
        },
      ],
    },
    true,
  );

  const finalizationSectionData = new SectionData(
    {
      title: _t('investor.investorProfileFinalizationTitle'),
      subTitle: _t('investor.investorProfileFinalizationSubTitle'),
      stages: [
        {
          name: 'contactDetails',
          progress: allProgress.contactDetails,
          isEditable: true,
          isHidden: true,
        },
        {
          name: 'bankDetails',
          progress: allProgress.bankDetails,
          isEditable: true,
        },
        {
          name: 'certification',
          progress: allProgress.certification,
        },
        {
          name: 'taxDeclaration',
          progress: allProgress.taxDeclaration,
        },
        {
          name: 'kyc',
          progress: allProgress.kyc,
        },
      ],
    },
    false,
  );

  const onStageActionBtnClick = (stageName: string) => {
    if (stageName === 'personalDetails') {
      dispatch(setPopup({ type: 'contact-details' }));
    }

    if (['questionnaire'].includes(stageName)) {
      const signatureStatus = _.get(onboarding, 'form.signatureStatus', '');

      if (signatureStatus === 'completed') {
        dispatch(setPopup({ type: 'questionnaire-answers' }));
      } else {
        dispatch(setPopup({ type: 'accreditation-questionnaire' }));
      }
    }

    if (stageName === 'bankDetails') {
      dispatch(setPopup({ type: 'bank-details' }));
    }

    if (stageName === 'taxDeclaration') {
      const taxDeclarationId = onboarding.form.taxDeclarationId;
      const taxDeclarationStatus = onboarding.form.taxDeclarationStatus;

      if (taxDeclarationId && taxDeclarationStatus === 'completed') {
        window.open(
          `${CONFIG.domainUrl}${DS_GET_CLIENT_ENVELOPE_DOCS.replace(':envelopeId', taxDeclarationId as string)}`,
          '_blank',
          '',
        );
      } else {
        dispatch(setPopup({ type: 'tax-declare' }));
      }
    }

    if (stageName === 'certification') {
      dispatch(setPopup({ type: 'accrediatation-certification' }));
    }

    if (stageName === 'kyc') {
      dispatch(setPopup({ type: 'kyc' }));
    }

    if (stageName === 'contactDetails') {
      dispatch(setPopup({ type: 'contact-details' }));
    }
  };

  const renderSection = (sectionData: SectionData) => {
    const { progress, stages } = sectionData;

    return (
      <section>
        <div className={css.sectionHeader}>
          <ContentWrap className={css.sectionHeaderContent}>
            <div className={css.titleAndText}>
              <p className={css.title}>{sectionData.title}</p>
              {!isMobile && <p className={css.subTitle}>{sectionData.subTitle}</p>}
            </div>
            <div className={css.progressWrap}>
              <div className={css.progressHeader}>
                <p>{_t(progress === 1 ? 'investor.progressCompleted' : 'investor.progress')}</p>
                <p>{Math.floor(progress * 100)}%</p>
              </div>
              <div className={css.progressBar}>
                <div className={css.progress} style={{ width: `${progress * 100}%` }} />
              </div>
            </div>
          </ContentWrap>
        </div>
        <div className={css.sectionStages}>
          {stages.map((stage, index) => {
            if (stage.isHidden) return null;
            let actionBtnLabel = _t('investor.fillStageFormBtnLabel');

            if (stage.progress >= 1) {
              actionBtnLabel = _t(`investor.${stage.isEditable ? 'editStageFormBtnLabel' : `viewStageFormBtnLabel`}`);
            }

            return (
              <div className={css.stage} key={index}>
                <ContentWrap className={css.stageContent}>
                  <p className={css.stageTitle}>{_t(`investor.${stage.name}StageName`)}</p>
                  {stage.progress >= 1 ? (
                    <p className={css.stageStatusCompleted}>{_t('investor.progressCompleted')}</p>
                  ) : (
                    <p className={css.stageStatusIncompleted}>{_t('investor.progressIncompleted')}</p>
                  )}
                  <Button className={css.actionBtn} onClick={() => onStageActionBtnClick(stage.name)}>
                    {isMobile ? <Icon className={css.editIcon} type="edit" /> : `${actionBtnLabel}`}
                  </Button>
                </ContentWrap>
              </div>
            );
          })}
        </div>
      </section>
    );
  };

  return (
    <div className={cn(css.investorProfile, className)}>
      {renderSection(onboardingSectionData)}
      {renderSection(finalizationSectionData)}
    </div>
  );
}

interface ISectionData {
  title: string;
  subTitle: string;
  progress: number;
  stages: {
    name: string;
    progress: number;
    isEditable?: boolean;
    isHidden?: boolean;
  }[];
}

class SectionData implements ISectionData {
  title;
  subTitle;
  stages;
  isQuestionnaire;

  constructor(data: { title: string; subTitle: string; stages: ISectionData['stages'] }, isQuestionnaire: boolean) {
    this.title = data.title;
    this.subTitle = data.subTitle;
    this.stages = data.stages;
    this.isQuestionnaire = isQuestionnaire;
  }

  get progress() {
    if (this.isQuestionnaire)
      return this.stages.reduce((acc, stage) => {
        if (stage.name === 'personalDetails') return acc + 0;
        return acc + stage.progress;
      }, 0);
    return this.stages.reduce((acc, stage) => acc + stage.progress, 0) / this.stages.length;
  }
}
