import React from 'react';
import { useLocation } from 'react-router';
import cn from 'classnames';

import { CONFIG } from 'constants/index';
import { analytics } from 'client/utils/analytics';
import { useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui/index';

import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { transitionClassNames } from 'client/utils/css-transition-classnames';

import { STAGES } from 'client/types';
import { useGetActiveStage, useGetStartScreen } from 'client/hooks';

import StepsIndicator from './StepsIndicator';

import Welcome from './Welcome';
import Thanks from './Thanks';
import Stages from './Stages';
import css from './AccreditationQuestionnaire.module.scss';

export type Screen = 'welcome' | 'questionnaire' | 'thanks';

const MainWrapper = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const activeStage = useGetActiveStage();
  const startScreen = useGetStartScreen();
  const [currentScreen, setCurrentScreen] = React.useState<Screen>(startScreen);
  const [activeQuestion, setActiveQuestion] = React.useState<number>(0);
  const [questionsLength, setQuestionsLength] = React.useState<number>(0);
  const [stage, setStage] = React.useState<STAGES>(activeStage);

  const setQuestion = (val: number) => {
    setActiveQuestion(val);
  };

  const setLength = (val: number) => {
    setQuestionsLength(val);
  };

  const setActiveStage = (val: STAGES) => {
    setStage(val);
  };

  const renderScreen = (screen: Screen) => {
    if (screen === 'welcome') {
      analytics.gtag.event('page_view', {
        page_title: `Questionnaire - welcome`,
        page_location: `${CONFIG.domainUrl}${location.pathname}`,
      });
    } else if (screen === 'thanks') {
      analytics.gtag.event('page_view', {
        page_title: `Questionnaire - Thank you`,
        page_location: `${CONFIG.domainUrl}${location.pathname}`,
      });
    }

    switch (screen) {
      case 'welcome':
        return <Welcome setScreen={() => setCurrentScreen('questionnaire')} />;
      case 'thanks':
        return <Thanks />;
      case 'questionnaire':
        return (
          <Stages
            setScreen={() => setCurrentScreen('thanks')}
            stage={stage}
            setStage={setActiveStage}
            setQuestion={setQuestion}
            setLength={setLength}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className={cn(css.mainWrapper, stage === 'nda' && css.nda)}>
      <div className={css.wrapper}>
        <div className={css.closeBtn} onClick={() => dispatch(setPopup(null))}></div>
        <StepsIndicator
          screen={currentScreen}
          stage={stage}
          activeQuestion={activeQuestion}
          questionsLength={questionsLength}
        />
        <div className={css.stageWrapper}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={currentScreen}
              timeout={300}
              classNames={contentTransitionClassNames}
              appear
              mountOnEnter
              unmountOnExit
            >
              {renderScreen(currentScreen)}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <div className={css.blurredBlock}></div>
    </div>
  );
};

const contentTransitionClassNames = transitionClassNames(css, 'stageTransition');

export default MainWrapper;
