import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { useAppDispatch } from 'client/hooks';
import { usePrev } from 'client/hooks/prev';
import { setMenuState } from 'client/redux/ui';
import { analytics } from 'client/utils/analytics';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { ContentWrap } from '../ContentWrap';
import { IconButton } from '../IconButton';

import { Logo } from './Logo';
import { Search } from './Search';
import { useCompanyData } from './use-company-data';
import menuIconUrl from 'client/assets/header/menu.svg';
import arrowBackIconUrl from 'client/assets/header/arrow-back.svg';
import searchIconUrl from 'client/assets/header/search.svg';
import css from './Header.module.scss';

interface Props {
  className?: string;
  id?: string;
}

const Header = (props: Props) => {
  const { className = '' } = props;
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const showMenuBtn = [
    routes.MAIN_PAGE_ROUTE,
    routes.USER_PROFILE_ROUTE,
    routes.SAVING_LIST_PAGE_ROUTE,
    routes.ADD_YOUR_COMPANY_PAGE_ROUTE,
    routes.MANDATES_PAGE_ROUTE,
  ].some((route) => route === location.pathname);

  const urlParams = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = React.useState(urlParams.get('search') || '');
  const [showSearch, setShowSearch] = React.useState(!!searchValue);

  const companyData = useCompanyData();
  const isLogoVisible = !companyData;
  const transitionKey = showSearch
    ? 'search'
    : isLogoVisible || !companyData
    ? 'logo'
    : companyData?.categories?.[0].name ?? '-';

  const history = useHistory();
  const prevLocation = usePrev(history.location);
  const prevShowSearch = usePrev(showSearch);
  const classNamesRef = React.useRef(
    getTransitionClassNames(history.location, prevLocation, showSearch || prevShowSearch),
  );

  if (history.location !== prevLocation || showSearch !== prevShowSearch) {
    classNamesRef.current = getTransitionClassNames(history.location, prevLocation, showSearch || prevShowSearch);
  }
  const classNames = classNamesRef.current;

  const onMenuBtnClick = React.useCallback(() => {
    dispatch(setMenuState('navigation'));
  }, [dispatch]);

  const onBackBtnClick = React.useCallback(() => {
    history.goBack();
  }, [history]);

  const onSearchClose = React.useCallback(() => {
    const urlParams = new URLSearchParams(history.location.search);

    if (!searchValue) setShowSearch(false);
    setSearchValue('');

    urlParams.delete('search');
    history.push(`${history.location.pathname}?${urlParams.toString()}`);
  }, [searchValue, history]);

  const onSearchSubmit = React.useCallback(() => {
    const urlParams = new URLSearchParams(history.location.search);

    urlParams.set('search', searchValue);
    urlParams.set('page', '1');

    analytics.gtag.event('search', {
      search_term: `${searchValue}`,
    });

    searchInputRef.current?.blur();

    history.push(`${routes.MAIN_PAGE_ROUTE}?${urlParams.toString()}`);
  }, [searchValue, history]);

  const onSearchBtnClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (searchValue) {
        onSearchSubmit();
      } else {
        setShowSearch(true);
      }
    },
    [searchValue, onSearchSubmit],
  );

  React.useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const search = urlParams.get('search');

    if (!search) {
      setShowSearch(false);
      setSearchValue('');
    }
  }, [history.location.search]);

  const renderHero = () => {
    if (showSearch) {
      return (
        <Search
          className={css.search}
          value={searchValue}
          inputRef={searchInputRef}
          onChange={setSearchValue}
          onSubmit={onSearchSubmit}
          onClose={onSearchClose}
        />
      );
    }

    if (isLogoVisible || !companyData) {
      return <Logo className={css.logo} />;
    }

    return <div className={css.category}>{companyData?.categories?.[0].name}</div>;
  };

  return (
    <div className={cn(css.header, className)}>
      <ContentWrap className={css.content}>
        {showMenuBtn ? (
          <IconButton className={css.menuButton} onClick={onMenuBtnClick}>
            <img src={menuIconUrl} alt="menu" />
          </IconButton>
        ) : (
          <IconButton className={css.backButton} onClick={onBackBtnClick}>
            <img src={arrowBackIconUrl} alt="back" />
          </IconButton>
        )}
        <TransitionGroup
          component={null}
          childFactory={(child) => {
            return React.cloneElement(child, { classNames });
          }}
        >
          <CSSTransition timeout={300} classNames={classNames} key={transitionKey} mountOnEnter unmountOnExit>
            <div className={css.hero}>{renderHero()}</div>
          </CSSTransition>
        </TransitionGroup>
        <IconButton className={css.searchButton} onClick={onSearchBtnClick}>
          <img src={searchIconUrl} alt="search" />
        </IconButton>
      </ContentWrap>
    </div>
  );
};

function getTransitionClassNames(
  current: { pathname: string; search: string },
  prev: { pathname: string; search: string },
  isSearchActive: boolean,
) {
  const currentSearch = new URLSearchParams(current.search).has('search');
  const prevSearch = new URLSearchParams(prev?.search ?? '').has('search');
  const page = Number(new URLSearchParams(current.search).get('page'));
  const prevPage = Number(new URLSearchParams(prev?.search ?? '').get('page'));
  const direction = page >= prevPage ? 'next' : 'prev';

  if (isSearchActive || currentSearch || prevSearch) {
    return transitionClassNames(css, 'heroTransitionSearch');
  }

  if (`${current.pathname}${current.search}` === '/' || direction === 'prev') {
    return transitionClassNames(css, 'heroTransitionForward'); // next drops from top
  }

  return transitionClassNames(css, 'heroTransitionBackward');
}

export default Header;
