import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect, RouteChildrenProps, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import * as routes from 'client/constants/routes';
import { Footer, PopUpManager, Profile, SavingList } from 'client/components/common/mobile';
import { useAppDispatch, useAppSelector } from 'client/hooks';
import { pushToRoutesHistory, setFooterState, setPopup } from 'client/redux/ui';
import { selectUI } from 'client/redux/ui/selectors';
import { selectAuth, selectUser } from 'client/redux/user/selectors';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { getMatchedRoute } from 'client/utils/get-matched-route';

import Main from 'client/components/pages/MainMobile';
import Company from 'client/components/pages/CompanyMobile';
import ClaimCompany from 'client/components/pages/ClaimCompany/Form';
import ClaimCompanyThanks from 'client/components/pages/ClaimCompany/Thanks';
import NotFound from 'client/components/pages/NotFound';
import FAQ from 'client/components/pages/FAQ';
import TermsOfUse from 'client/components/pages/TermsOfUse';
import PrivacyPolicy from 'client/components/pages/PrivacyPolicy';
import Disclaimer from 'client/components/pages/Disclaimer';
import CyberSecurity from 'client/components/pages/CyberSecurity';
import ConsentForm from 'client/components/pages/ConsentForm';
import AccessibilityStatement from 'client/components/pages/AccessibilityStatement';
import Mandate from './pages/Mandate';
import Mandates from './pages/Mandates';

import favicon16 from 'client/assets/favicon/favicon-16x16.png';
import favicon32 from 'client/assets/favicon/favicon-32x32.png';
import faviconApple from 'client/assets/favicon/apple-touch-icon.png';

import css from './Mobile.module.scss';

interface Props extends React.PropsWithChildren {
  className?: string;
}

function MobileApp(props: Props) {
  const { className = '' } = props;
  const { footer } = useAppSelector(selectUI);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isLoggedIn } = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);

  // console.log('isLoggedIn', isLoggedIn);

  React.useEffect(() => {
    const currentRoute = getMatchedRoute(location.pathname) || routes.MAIN_PAGE_ROUTE;

    dispatch(pushToRoutesHistory({ route: currentRoute, pathname: location.pathname, search: location.search }));
    dispatch(setFooterState(getFooterVisibility(location) ? 'visible' : 'hidden'));
  }, [location, dispatch]);

  React.useEffect(() => {
    if (isLoggedIn && user.role === 'VIEWER') {
      dispatch(setPopup({ type: 'join-as-investor', data: { link: '', isExternalLink: false } }));
    }
  }, [isLoggedIn]);

  return (
    <div className={cn(css.app, className)}>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href={`${favicon16}`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${faviconApple}`} />
      </Helmet>
      <Switch>
        <Route exact path={routes.COMPANY_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.SAVING_LIST_PAGE_ROUTE} component={AnimatedRoute} />
        <Route path={routes.USER_PROFILE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.ADD_YOUR_COMPANY_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.ADD_YOUR_COMPANY_THANKS_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.FAQ_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.TERMS_OF_USE_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.PRIVACY_POLICY_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.DISCLAIMER_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.CYBER_SECURITY_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.CONSENT_FORM_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.ACCESSIBILITY_STATETMENT_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.NOT_FOUND_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.MAIN_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.SIGN_IN_ROUTE} component={Main} />
        <Route exact path={routes.SIGN_UP_ROUTE} component={Main} />
        <Route exact path={routes.MANDATE_PAGE_ROUTE} component={AnimatedRoute} />
        <Route exact path={routes.MANDATES_PAGE_ROUTE} component={AnimatedRoute} />
        <Redirect to={routes.MAIN_PAGE_ROUTE} />
      </Switch>
      <Footer className={css.footer} hidden={footer === 'hidden'} />
      <PopUpManager className={css.popUpManager} />
    </div>
  );
}

function AnimatedRoute(props: RouteChildrenProps) {
  const route = props.match?.path || routes.MAIN_PAGE_ROUTE;
  const prevRoute = useAppSelector(selectUI).routesHistory.at(-1)?.route || routes.MAIN_PAGE_ROUTE;
  const classNames = React.useMemo(() => getTransitionClassNames(route, prevRoute), [route, prevRoute]);
  const transitionKey = route === routes.MAIN_PAGE_ROUTE ? route : props.location.pathname;
  let Page: React.ComponentType<any>;

  switch (route) {
    case routes.MAIN_PAGE_ROUTE:
      Page = Main;
      break;

    case routes.COMPANY_PAGE_ROUTE:
      Page = Company;
      break;

    case routes.NOT_FOUND_PAGE_ROUTE:
      Page = NotFound;
      break;

    case routes.SAVING_LIST_PAGE_ROUTE:
      Page = SavingList;
      break;

    case routes.USER_PROFILE_ROUTE:
      Page = Profile;
      break;

    case routes.ADD_YOUR_COMPANY_PAGE_ROUTE:
      Page = ClaimCompany;
      break;

    case routes.ADD_YOUR_COMPANY_THANKS_PAGE_ROUTE:
      Page = ClaimCompanyThanks;
      break;

    case routes.FAQ_PAGE_ROUTE:
      Page = FAQ;
      break;

    case routes.TERMS_OF_USE_PAGE_ROUTE:
      Page = TermsOfUse;
      break;

    case routes.PRIVACY_POLICY_PAGE_ROUTE:
      Page = PrivacyPolicy;
      break;

    case routes.DISCLAIMER_PAGE_ROUTE:
      Page = Disclaimer;
      break;

    case routes.CYBER_SECURITY_PAGE_ROUTE:
      Page = CyberSecurity;
      break;

    case routes.CONSENT_FORM_PAGE_ROUTE:
      Page = ConsentForm;
      break;

    case routes.ACCESSIBILITY_STATETMENT_PAGE_ROUTE:
      Page = AccessibilityStatement;
      break;

    case routes.MANDATE_PAGE_ROUTE:
      Page = Mandate;
      break;

    case routes.MANDATES_PAGE_ROUTE:
      Page = Mandates;
      break;

    default:
      Page = Main;
      break;
  }

  return (
    <TransitionGroup
      component={null}
      appear
      enter
      exit
      childFactory={(child) => {
        return React.cloneElement(child, { classNames });
      }}
    >
      <CSSTransition key={transitionKey} timeout={300} classNames={classNames} unmountOnExit>
        <div className={css.page}>
          <Page {...props} />
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

function getTransitionClassNames(route: string, prevRoute?: string) {
  const nestedRoutes = [routes.COMPANY_PAGE_ROUTE, routes.MANDATE_PAGE_ROUTE, routes.MANDATES_PAGE_ROUTE];

  if (nestedRoutes.includes(route)) {
    if (route === prevRoute) {
      return transitionClassNames(css, 'pageTransitionFade');
    }
    return transitionClassNames(css, 'pageTransitionForward');
  }

  if (route === routes.MAIN_PAGE_ROUTE) {
    return transitionClassNames(css, 'pageTransitionBackward');
  }

  return transitionClassNames(css, 'pageTransitionFade');
}

function getFooterVisibility(location: ReturnType<typeof useLocation>) {
  const page = Number(new URLSearchParams(location.search).get('page'));
  const currentRoute = getMatchedRoute(location.pathname) || routes.MAIN_PAGE_ROUTE;

  switch (currentRoute) {
    case routes.MAIN_PAGE_ROUTE:
      return !!page;

    default:
      return true;
  }
}

export default MobileApp;
