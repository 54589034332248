import cn from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';

import { CONFIG } from 'constants/index';
import { Button as MobileButton } from 'client/components/common/mobile';
import { useResponsive, useTranslation } from 'client/hooks';
import { MAIN_PAGE_ROUTE, NOT_FOUND_PAGE_ROUTE } from 'client/constants/routes';
import { analytics } from 'client/utils/analytics';
import { useAppSelector } from 'client/hooks/redux';
import { selectUserGAStatus } from 'client/redux/user/selectors';
import { Header } from 'client/components/common/mobile';
import { Button } from 'client/components/common';

import bgImage from 'client/assets/not-found-bg-image.png';
import notFound from 'client/assets/not-found.svg';

import css from './NotFound.module.scss';

interface Props {
  className?: string;
}

const NotFound: React.FC<Props> = (props) => {
  const { className = '' } = props;

  const { translate: t } = useTranslation('notFound');
  const [isDesktop] = useResponsive('DESKTOP');
  const history = useHistory();
  const userGAStatus = useAppSelector(selectUserGAStatus);

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `404`,
        page_location: `${CONFIG.domainUrl}${NOT_FOUND_PAGE_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onBackBtnClick = () => {
    history.push(MAIN_PAGE_ROUTE.replace(':id?', ''));
  };

  return (
    <div className={cn(css.notFound, className)}>
      <Header className={css.header} id="not-found" />
      <div className={css.bg}>
        <img src={bgImage} className={css.bgImage} alt="bg" />
        <div className={css.gradient} />
        <div className={css.mask} />
      </div>
      <div className={css.content}>
        <div className={css.top}>
          <img src={notFound} alt="" />
        </div>
        <div className={css.title}>{t('title')}</div>
        <div className={css.subtitle}>{t('subtitle')}</div>
        {isDesktop ? (
          <Button size="medium" onClick={onBackBtnClick} className={css.backBtn}>
            {`←${t('actionBtnLabel')}`}
          </Button>
        ) : (
          <MobileButton size="x-large-block" className={css.backBtn} onClick={onBackBtnClick}>
            {`←${t('actionBtnLabel')}`}
          </MobileButton>
        )}
      </div>
    </div>
  );
};
export { NotFound };
export default NotFound;
