import React from 'react';
import { useHistory } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import _ from 'lodash';

import { MainPageWebsiteSliderItem } from 'api/main_page/public/get/params';
import { Company as CompanyData } from 'api/companies/public/paginate/params';
import { videoStatistics as videoStatisticsApi } from 'client/redux/api/video-statistics';
import { COMPANY_PAGE_ROUTE, MANDATE_PAGE_ROUTE } from 'client/constants/routes';
import { Button, VideoJSPlayer, CompanyActions } from 'client/components/common/mobile';
import { transitionClassNames } from 'client/utils/css-transition-classnames';
import { imgixUrl } from 'client/utils/imgix-url';
import playBtnIconUrl from 'client/assets/player/icon-play.svg';
import { useAppSelector, useAppDispatch } from 'client/hooks';
import { selectAuth, selectUserRole } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';
import noAvatarImageUrl from 'client/assets/default-avatar.svg';
import { selectData } from 'client/redux/user/selectors';

import Splash from './Splash';
import css from './Company.module.scss';

interface Props {
  className?: string;
  position?: 'prev' | 'current' | 'next';
  data?: CompanyData | MainPageWebsiteSliderItem | null;
}

export function Company(props: Props) {
  const { data, position = 'current', className = '' } = props;
  const [isVideoPlayerActive, setIsVideoPlayerActive] = React.useState(false);
  const autoplayTimoutId = React.useRef<NodeJS.Timeout | null>(null);
  const history = useHistory();
  const userData = useAppSelector(selectData);
  const location = history.location;
  const hasDecorImage = !!data?.decorImage;
  const isAlreadyVoted = userData.voted.includes(data?._id || '');
  const isInFavoriteList = userData.favorite.includes(data?._id || '');
  const [updateVideoStatistics] = videoStatisticsApi.endpoints.updateVideoStatistics.useMutation();
  const { isLoggedIn } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);

  React.useEffect(() => {
    const page = Number(new URLSearchParams(location.search).get('page'));

    setIsVideoPlayerActive(false);

    if (page && position === 'current') {
      autoplayTimoutId.current = setTimeout(() => {
        setIsVideoPlayerActive(true);
      }, 1800);
    }

    return () => {
      if (autoplayTimoutId.current) clearTimeout(autoplayTimoutId.current);
    };
  }, [position, location.search]);

  React.useEffect(() => {
    if (!data) return;
    const isNeedRedirect = localStorage.getItem('mandate');
    if (isMandate(data)) {
      if (isLoggedIn && isNeedRedirect && userRole === 'VIEWER') {
        _.defer(() => {
          dispatch(
            setPopup({
              type: 'join-as-investor',
              data: { link: MANDATE_PAGE_ROUTE.replace(':id', data.mandateInfo?.id || ''), isExternalLink: false },
            }),
          );
          localStorage.removeItem('mandate');
        }, 0);
      }
      if (isLoggedIn && isNeedRedirect && userRole !== 'VIEWER') {
        history.push(MANDATE_PAGE_ROUTE.replace(':id', data.mandateInfo?.id || ''));
        localStorage.removeItem('mandate');
      }
    }
  }, [isLoggedIn]);

  const onSeeMoreClick = () => {
    if (!data) throw new Error('Company data is not defined');

    if (isMandate(data)) {
      if (!data.mandateInfo?.id) throw new Error('Mandate id is not defined');
      if (userRole === 'VIEWER' && !isLoggedIn) {
        dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
        localStorage.setItem('mandate', data.mandateInfo.id);
        return;
      }
      if (userRole === 'VIEWER' && isLoggedIn) {
        dispatch(
          setPopup({
            type: 'join-as-investor',
            data: { link: MANDATE_PAGE_ROUTE.replace(':id', data.mandateInfo.id), isExternalLink: false },
          }),
        );
        return;
      }

      history.push(MANDATE_PAGE_ROUTE.replace(':id', data.mandateInfo.id), { from: history.location.pathname });
    } else {
      history.push(COMPANY_PAGE_ROUTE.replace(':id', data._id), { from: history.location.pathname });
    }
  };

  const onPreviewClick = () => {
    setIsVideoPlayerActive(true);
  };

  const onClosePlayerClick = React.useCallback(() => {
    setIsVideoPlayerActive(false);
    if (autoplayTimoutId.current) clearTimeout(autoplayTimoutId.current);
  }, []);

  const onPlayerVideoComplete = React.useCallback(() => {
    setIsVideoPlayerActive(false);
    if (autoplayTimoutId.current) clearTimeout(autoplayTimoutId.current);
  }, []);

  const onStatisticUpdate = async (st: { fileName: string; progress: number }) => {
    const { fileName, progress } = st;
    await updateVideoStatistics({ fileName, progress });
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={!data ? 'splash' : 'content'}
        timeout={200}
        classNames={companyTransitionClassNames}
        mountOnEnter
        unmountOnExit
      >
        {data ? (
          <div
            className={cn(
              css.company,
              css[position],
              isVideoPlayerActive && css.videoPlayerActive,
              isMandate(data) && css.mandate,
              className,
            )}
          >
            <div
              className={cn(css.bg, hasDecorImage && css.withDecorImage)}
              style={{
                backgroundImage: `url(${imgixUrl(data.mainImage, {
                  auto: 'compress',
                  q: 90,
                  'max-w': 1000,
                  fit: 'fill',
                })})`,
              }}
            />
            <div className={cn(css.previewContainer, hasDecorImage && css.withDecorImage)} onClick={onPreviewClick}>
              {hasDecorImage && (
                <div
                  className={css.preview}
                  style={{
                    backgroundImage: `url(${imgixUrl(data.decorImage, {
                      auto: 'compress',
                      q: 90,
                      'max-w': 1000,
                      fit: 'fill',
                    })})`,
                  }}
                />
              )}
              <button className={css.playBtn} type="button">
                <img src={playBtnIconUrl} alt="play" />
              </button>
            </div>
            <div className={css.info}>
              {isMandate(data) && (
                <div className={css.raisingTag}>
                  <span>Raising</span>
                </div>
              )}
              <div className={css.titleWrap}>
                <p className={css.title}>{data.name}</p>
                <div className={css.actions}>
                  {isMandate(data) === false && (
                    <CompanyActions
                      isAlreadyVoted={isAlreadyVoted}
                      isInFavoriteList={isInFavoriteList}
                      companyId={data._id}
                    />
                  )}
                </div>
              </div>
              <p className={css.description}>{data.subTitle}</p>
            </div>
            <div className={css.hr} />
            <div className={css.founders}>
              <p className={css.foundersTitle}>Founders</p>
              <div className={css.foundersListWrap}>
                <div className={css.foundersList}>
                  {data.team?.map((founder, index) => (
                    <div className={css.founder} key={index}>
                      <div
                        className={css.founderAvatar}
                        style={{
                          backgroundImage: `url(${
                            founder.avatar
                              ? imgixUrl(founder.avatar, {
                                  q: 85,
                                  auto: 'compress',
                                  fit: 'fill',
                                  'max-w': 200,
                                })
                              : noAvatarImageUrl
                          })`,
                        }}
                      />
                      <div className={css.founderInfo}>
                        <p className={css.founderInfoName}>{founder.name}</p>
                        <p className={css.founderInfoPosition}>{founder.role}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <Button className={css.actionBtn} size="small" onClick={onSeeMoreClick}>
                  {isMandate(data) ? 'Invest now' : 'See more'}
                </Button>
              </div>
            </div>
            {isVideoPlayerActive && (
              <div className={cn(css.playerWrap, className)}>
                <div className={css.playerBg} onClick={onClosePlayerClick} />
                <div className={css.playerContainer}>
                  <VideoJSPlayer
                    id={`player_${data._id}`}
                    videoSRC={data.avatarVideo}
                    onComplete={onPlayerVideoComplete}
                    onStatisticUpdate={(st) => onStatisticUpdate(st)}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={cn(css.company, css[position], className)}>
            <Splash className={css.splash} />
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
}

// FIXME: appearance transition is not working
const companyTransitionClassNames = transitionClassNames(css, 'companyTransition');

const isMandate = (data: CompanyData | MainPageWebsiteSliderItem | null): data is MainPageWebsiteSliderItem => {
  return (data as MainPageWebsiteSliderItem)?.mandateInfo !== undefined;
};

export default Company;
