import { CONFIG } from 'src/constants';

export const API_HOST = CONFIG.domainUrl;

// as const is a type assertion that tells TypeScript that the value of ROOT is a string literal type:
// so in hints it will show you the value of ROOT as `/api` instead of `string`
export const ROOT = `/api` as const;

export const AUTH_ROUTE = `${ROOT}/auth` as const;
export const CATEGORY_ROUTE = `${ROOT}/category` as const;
export const DOCUSIGN_ROUTE = `${ROOT}/ds` as const;
export const COMPANY_ROUTE = `${ROOT}/company` as const;
export const COMPANY_CLAIMS_ROUTE = `${ROOT}/company-claims` as const;
export const FAQ_ROUTE = `${ROOT}/faq` as const;
export const FUND_ROUTE = `${ROOT}/fund` as const;
export const OFFER_ROUTE = `${ROOT}/offer` as const;
export const USERS_ROUTE = `${ROOT}/users` as const;
export const FAKE_MANDATES_SLIDES_ROUTE = `${ROOT}/fake-mandates-slides` as const;

// Admin:
export const AUTH_ADMIN = `${AUTH_ROUTE}/admin` as const;
export const AUTH_SESSION_IN = `${AUTH_ROUTE}/session-in` as const;
export const AUTH_SESSION_OUT = `${AUTH_ROUTE}/session-out` as const;

export const ADMINS_CREATE = `${ROOT}/admins/create` as const;
export const ADMINS_UPDATE = `${ROOT}/admins/update/:id` as const;
export const ADMINS_DELETE = `${ROOT}/admins/delete/:id` as const;
export const ADMINS_PAGINATE = `${ROOT}/admins/paginate` as const;

// User:
export const AUTH_USER = `${AUTH_ROUTE}/user` as const;
export const AUTH_USER_ME = `${AUTH_ROUTE}/user/me` as const;
export const AUTH_USER_PROVIDER_CB = `${AUTH_ROUTE}/user/:provider/cb` as const;
export const SIGN_UP_USER_WITH_EMAIL = `${AUTH_ROUTE}/user/sign-up-email` as const;
export const AUTH_FORGOT_PASSWORD = `${AUTH_ROUTE}/user/forgot-password` as const;
export const AUTH_RESTORE_PASSWORD = `${AUTH_ROUTE}/user/restore-password` as const;

export const USERS_UPDATE = `${ROOT}/users/update/:id` as const;
export const USERS_WEBSITE_UPDATE = `${ROOT}/users/:id/website/update` as const;
export const USERS_CHANGE_PASSWORD = `${ROOT}/users/:id/change-password` as const;
export const USERS_UPDATE_STATUS = `${ROOT}/users/update/:id/status` as const;
export const USERS_PAGINATE = `${ROOT}/users/paginate` as const;
export const USERS_GET_USER_INFO = `${ROOT}/users/:id` as const;

export const USER_ONBOARDING_GET = `${ROOT}/user/onboarding` as const;
export const USER_PORTFOLIO_GET = `${ROOT}/user/portfolio` as const;
export const USER_ONBOARDING_UPDATE = `${ROOT}/user/onboarding` as const;
export const USER_GET_PRIVATE_ASSETS = `${ROOT}/user/get-private-assets` as const;

// User's favorite/voted list of companies:
export const USERS_FAVORITE_LIST_GET = `${ROOT}/list/favorite/get` as const;
export const USERS_FAVORITE_VOTED_INFO_GET = `${ROOT}/list/favorite-voted-info/get` as const;
export const USERS_FAVORITE_LIST_CHECK = `${ROOT}/list/favorite/check` as const;
export const USERS_VOTED_LIST_CHECK = `${ROOT}/list/voted/check` as const;
export const USERS_FAVORITE_LIST_ADD = `${ROOT}/list/favorite/add` as const;
export const USERS_FAVORITE_LIST_DELETE = `${ROOT}/list/favorite/delete` as const;

// Offer:
export const OFFER_CREATE = `${ROOT}/offer/create` as const;
export const OFFER_UPDATE = `${ROOT}/offer/update/:id` as const;
export const OFFER_PAGINATE = `${ROOT}/offer/target/:targetName/:id/paginate` as const; // target name can be 'company' | 'fund'
export const OFFER_PAGINATE_BY_USER = `${ROOT}/offer/user/:id/paginate` as const;
export const OFFER_PAGINATE_WEBSITE = `${ROOT}/offer/website/paginate` as const;
export const OFFER_GET_WEBSITE = `${ROOT}/offer/website/get` as const;

// Statistics:
export const STATISTICS_UPDATE = `${ROOT}/statistics/update` as const;
export const STATISTICS_PAGINATE = `${ROOT}/statistics/:targetName/:id/paginate` as const; // target name can be 'company' | 'fund'

// Category:
export const CATEGORY_CREATE = `${CATEGORY_ROUTE}/create` as const;
export const CATEGORY_UPDATE = `${CATEGORY_ROUTE}/update/:id` as const;
export const CATEGORY_GET = `${CATEGORY_ROUTE}/get/:id` as const;
export const CATEGORY_WEBSITE_GET = `${CATEGORY_ROUTE}/website/get/:id` as const;
export const CATEGORY_GET_ALL = `${CATEGORY_ROUTE}/get-all` as const;
export const CATEGORY_WEBSITE_GET_ALL = `${CATEGORY_ROUTE}/website/get-all` as const;
export const CATEGORY_CHANGE_ORDER = `${CATEGORY_ROUTE}/change-order` as const;
export const CATEGORY_COMPANIES_CHANGE_ORDER = `${CATEGORY_ROUTE}/companies-change-order` as const;

// Company (admin):
export const COMPANY_CREATE = `${COMPANY_ROUTE}/create` as const;
export const COMPANY_UPDATE = `${COMPANY_ROUTE}/update/:id` as const;
export const COMPANY_DELETE = `${COMPANY_ROUTE}/delete/:id` as const;
export const COMPANY_PAGINATE = `${COMPANY_ROUTE}/paginate` as const;
export const COMPANY_CATEGORY_ORDER_PAGINATE = `${COMPANY_ROUTE}/paginate-category-order` as const;
export const COMPANY_CHANGE_ORDER = `${COMPANY_ROUTE}/change-order` as const;

// Company reports
export const COMPANY_REPORTS_CREATE = `${COMPANY_ROUTE}/:id/reports/create` as const;
export const COMPANY_REPORTS_UPDATE = `${COMPANY_ROUTE}/:id/reports/:reportId/update` as const;
export const COMPANY_REPORTS_PAGINATE = `${COMPANY_ROUTE}/:id/reports/paginate` as const;
export const COMPANY_REPORTS_DELETE = `${COMPANY_ROUTE}/reports/:reportId/delete` as const;
export const COMPANY_REPORTS_GET_BY_USER_INVESTMENTS = `${COMPANY_ROUTE}/reports/get-by-user-investments` as const;
export const COMPANY_REPORTS_GET_DOC_FILE = `${COMPANY_ROUTE}/:id/reports/get-document-file` as const;

// Company (website):
export const COMPANY_GET = `${COMPANY_ROUTE}/website/get/:id` as const;
export const COMPANY_WEBSITE_PAGINATE = `${COMPANY_ROUTE}/website/paginate` as const;
export const COMPANY_WEBSITE_UPDATE_RATING = `${COMPANY_ROUTE}/website/update-rating` as const;
export const COMPANY_WEBSITE_PAGINATE_WITH_CATEGORY_ORDER =
  `${COMPANY_ROUTE}/website/paginate-with-category-order` as const;

// Company claims:
export const COMPANY_CLAIM_DELETE = `${COMPANY_CLAIMS_ROUTE}/delete/:id` as const;
export const COMPANY_CLAIM_PAGINATE = `${COMPANY_CLAIMS_ROUTE}/paginate` as const;
// Company claims (website):
export const COMPANY_CLAIM_CREATE = `${COMPANY_CLAIMS_ROUTE}/website/create` as const;

// Mandate:
export const MANDATE_CREATE = `${ROOT}/mandate/create` as const;
export const MANDATE_UPDATE = `${ROOT}/mandate/update/:id` as const;
export const MANDATE_DELETE = `${ROOT}/mandate/delete/:id` as const;
export const MANDATE_PAGINATE = `${ROOT}/mandate/paginate` as const;
export const MANDATE_GET = `${ROOT}/mandate/get` as const;
// Mandate (website):
export const W_MANDATE_GET = `${ROOT}/mandate/website/get/:id` as const;
export const W_MANDATE_GET_PRIVATE_ASSETS = `${ROOT}/mandate/website/get-private-assets` as const;

// Fund:
export const FUND_CREATE = `${FUND_ROUTE}/create` as const;
export const FUND_UPDATE = `${FUND_ROUTE}/update/:id` as const;
export const FUND_DELETE = `${FUND_ROUTE}/delete/:id` as const;
export const FUND_PAGINATE = `${FUND_ROUTE}/paginate` as const;

// Video:
export const VIDEO_TRANSCODE = `${ROOT}/video/transcode` as const;
export const VIDEO_UPLOAD = `${ROOT}/video/upload` as const;
export const VIDEO_GET = `${ROOT}/video.m3u8` as const;
export const VIDEO_GET_STREAM_M3U8 = `${ROOT}/video/stream` as const;
export const VIDEO_WEBSITE_GET_STREAM_M3U8 = `${ROOT}/video/website/stream` as const;
export const VIDEO_TRANSCODER_JOB = `${ROOT}/video/transcoder-job/:id` as const;

// Video statistics
export const VIDEO_STATS_GET = `${ROOT}/video-stats/get` as const;
export const VIDEO_STATS_UPDATE = `${ROOT}/video-stats/update` as const;

// Main page content:
export const MAIN_PAGE_GET = `${ROOT}/main-page-content/get` as const;
export const MAIN_PAGE_UPDATE = `${ROOT}/main-page-content/update` as const;
// Main page content (website):
export const MAIN_PAGE_WEBSITE_GET = `${ROOT}/main-page-content/website/get` as const;

// FAQ:
export const FAQ_GET = `${FAQ_ROUTE}/get` as const;
export const FAQ_WEBSITE_GET = `${FAQ_ROUTE}/website/get` as const;
export const FAQ_CREATE = `${FAQ_ROUTE}/create` as const;
export const FAQ_UPDATE = `${FAQ_ROUTE}/update/:id` as const;
export const FAQ_DELETE = `${FAQ_ROUTE}/delete/:id` as const;
export const FAQ_CHANGE_ORDER = `${FAQ_ROUTE}/order` as const;

// Other:
export const OTHER_GENERATE_ID = `${ROOT}/other/generate-id` as const;

export const STATIC_PAGES_GET = `${ROOT}/other/static-pages/get` as const;
export const STATIC_PAGES_UPDATE = `${ROOT}/other/static-pages/update` as const;

// KYC:
export const KYC_GENERATE_ACCESS_TOKEN = `${ROOT}/kyc/generate-access-token` as const;
export const KYC_WEBHOOK = `${ROOT}/kyc/webhook` as const;

//News
export const NEWS_CREATE = `${ROOT}/news/create` as const;
export const NEWS_UPDATE = `${ROOT}/news/update/:id` as const;
export const NEWS_DELETE = `${ROOT}/news/delete/:id` as const;
export const NEWS_PAGINATE = `${ROOT}/news/paginate` as const;
export const NEWS_WEBSITE_GET = `${ROOT}/news/website/get` as const;

// DocuSign:
export const DS_GET_ENVELOPE = `${DOCUSIGN_ROUTE}/get-user-envelope` as const;
export const DS_GET_ENVELOPE_DOCS = `${DOCUSIGN_ROUTE}/envelopes/:envelopeId/documents` as const;
export const DS_GET_CLIENT_ENVELOPE_DOCS = `${DOCUSIGN_ROUTE}/client-envelopes/:envelopeId/documents` as const;
export const DS_WEBHOOK = `${DOCUSIGN_ROUTE}/webhook` as const;

// hunbspot
export const HUBSPOT_ROUTE = `${ROOT}/hubspot` as const;
export const HUBSPOT_CONTACT_UPDATE = `${HUBSPOT_ROUTE}/update` as const;
export const HUBSPOT_LOGS_PAGINATE = `${ROOT}/hubspot-logs/paginate` as const;
export const HUBSPOT_CONTACT_WEBSITE_CREATE = `${HUBSPOT_ROUTE}/website/create` as const;
export const HUBSPOT_CONTACT_WEBSITE_UPDATE = `${HUBSPOT_ROUTE}/website/update` as const;

// Fake mandates slides:
export const FAKE_MANDATES_SLIDES_CREATE = `${FAKE_MANDATES_SLIDES_ROUTE}/create` as const;
export const FAKE_MANDATES_SLIDES_UPDATE = `${FAKE_MANDATES_SLIDES_ROUTE}/update/:id` as const;
export const FAKE_MANDATES_SLIDES_DELETE = `${FAKE_MANDATES_SLIDES_ROUTE}/delete/:id` as const;
export const FAKE_MANDATES_SLIDES_GET_ALL = `${FAKE_MANDATES_SLIDES_ROUTE}/get-all` as const;
export const FAKE_MANDATES_SLIDES_CHANGE_ORDER = `${FAKE_MANDATES_SLIDES_ROUTE}/change-order` as const;
export const WEBSITE_FAKE_MANDATES_SLIDES_GET_ALL = `${FAKE_MANDATES_SLIDES_ROUTE}/website/get-all` as const;
