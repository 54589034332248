import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import ContentWrap from '../ContentWrap';
import css from './PageNavigation.module.scss';

interface Props {
  className?: string;
  stickyMode?: boolean;
  anchorMode?: boolean;
  navigation: {
    label: string;
    path: string;
    hasPendingAction?: boolean;
  }[];
  logo?: React.ReactNode;
}

export const PageNavigation = (props: Props) => {
  const { navigation, className = '', logo = null, stickyMode = false, anchorMode = false } = props;
  const [currentAnchor, setCurrentAnchor] = React.useState<string | null>(navigation[0].path);

  React.useEffect(() => {
    if (!stickyMode || !anchorMode) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCurrentAnchor('#' + entry.target.id);
          }
        });
      },
      { threshold: 0, rootMargin: '-60% 0px -40% 0px', root: null },
    );

    navigation.forEach((item) => {
      const anchor = document.querySelector(item.path);
      if (anchor) observer.observe(anchor);
    });
  }, [stickyMode, anchorMode, navigation]);

  return (
    <nav className={cn(css.pageNavigation, stickyMode && css.stickyMode, className)}>
      <ContentWrap className={css.pageNavigationContentWrap}>
        <div className={css.pageNavigationContent}>
          <ul className={css.pageNavigationList}>
            {navigation.map((item) => (
              <li className={css.pageNavigationItem} key={item.path}>
                {item.hasPendingAction && <div className={css.pendingAction}></div>}
                {!anchorMode ? (
                  <NavLink
                    exact
                    to={item.path}
                    className={(isActive) => cn(css.pageNavigationLink, isActive && css.active)}
                  >
                    {item.label}
                  </NavLink>
                ) : (
                  <a href={item.path} className={cn(css.pageNavigationLink, currentAnchor === item.path && css.active)}>
                    {item.label}
                  </a>
                )}
              </li>
            ))}
          </ul>
          {logo && <div className={css.pageNavigationLogo}>{logo}</div>}
        </div>
      </ContentWrap>
    </nav>
  );
};

export default PageNavigation;
