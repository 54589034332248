import React from 'react';
import { FormikProvider, Form, useFormik } from 'formik';

import { useResponsive, useTranslation } from 'client/hooks';
import { companyClaims as companyClaimsApi } from 'client/redux/api/company-claims';
import { paramsSchemaClientForm as createParamsSchema } from 'api/company_claims/create/params';
import MobileButton from 'client/components/common/mobile/Button';
import { FormValuesType } from './types';
import { REG_EXP } from 'client/constants';
// import { CONFIG } from 'constants/index';
import { CONSENT_FORM_PAGE_ROUTE } from 'client/constants/routes';

import { TextField, Checkbox } from 'client/components/common/form';
import { Loader } from 'client/components/common';
import css from './Form.module.scss';

const initialValues = {
  fullName: '',
  companyName: '',
  email: '',
  isNewsletterEnabled: false,
  videoUrl: '',
  websiteUrl: '',
  phone: '',
  checkbox2: false,
};

interface Props {
  onSubmitSuccess: () => void;
}

const CONSERT_FORM_LINK = `${CONSENT_FORM_PAGE_ROUTE}`;

const FormWrapper = ({ onSubmitSuccess }: Props) => {
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate } = useTranslation();
  const [createClaim, createClaimState] = companyClaimsApi.endpoints.createClaim.useMutation();
  const formikContext = useFormik<FormValuesType>({
    enableReinitialize: true,
    validationSchema: createParamsSchema,
    initialValues: initialValues,
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { checkbox2, ...payload } = values;
      const createClaimResult = await createClaim(payload).unwrap();

      if (createClaimResult.success) {
        onSubmitSuccess();
      }
    },
  });

  return (
    <FormikProvider value={formikContext}>
      <Form className={css.formWrapper}>
        <div className={css.row}>
          <div className={css.fieldWrapper}>
            <TextField name="fullName" label={translate('claimCompany.form.inputsLabels.fullName')} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.fieldWrapper}>
            <TextField name="companyName" label={translate('claimCompany.form.inputsLabels.companyName')} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.fieldWrapper}>
            <TextField
              name="email"
              valuePattern={REG_EXP.EMAIL}
              label={translate('claimCompany.form.inputsLabels.email')}
            />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.fieldWrapper}>
            <TextField name="videoUrl" label={translate('claimCompany.form.inputsLabels.videoUrl')} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.fieldWrapper}>
            <TextField name="websiteUrl" label={translate('claimCompany.form.inputsLabels.websiteUrl')} />
          </div>
        </div>
        <div className={css.row}>
          <div className={css.fieldWrapper}>
            <TextField
              name="phone"
              valuePattern={REG_EXP.NUMBERSPLUSMINUS}
              label={translate('claimCompany.form.inputsLabels.phone')}
            />
          </div>
        </div>
        <div className={css.checkBoxRow}>
          <Checkbox name="isNewsletterEnabled" label={translate('claimCompany.form.checkboxesLabels.first')} />
        </div>
        <div className={css.checkBoxRow}>
          <Checkbox
            name="checkbox2"
            label={translate('claimCompany.form.checkboxesLabels.second').replace('{link}', CONSERT_FORM_LINK)}
          />
        </div>
        <div className={css.buttonWrapper}>
          {isMobile ? (
            <MobileButton size="x-large-block" type="submit">
              {translate('claimCompany.form.submitBtn')}
            </MobileButton>
          ) : (
            <button type="submit" className={css.submitBtn}>
              {translate('claimCompany.form.submitBtn')}
            </button>
          )}
        </div>
        {createClaimState.isLoading && <Loader position="absolute" className={css.formLoader} />}
      </Form>
    </FormikProvider>
  );
};

export default FormWrapper;
