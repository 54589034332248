export enum LINK_TYPE {
  WATCH = 'watch',
  SEE_MORE = 'seeMore',
}

export enum SIZE_TYPE {
  SM = 'sm',
  M = 'm',
  L = 'l',
}

export enum LOCALE {
  EN = 'en',
  HE = 'he',
}

export type STAGES = 'personalDetails' | 'questionnaire' | 'nda';

export type MediaType = string | ArrayBuffer | null | undefined | File;
