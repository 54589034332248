import React from 'react';
import cn from 'classnames';

import { Result } from 'api/companies/public/get/params';
import { imgixUrl } from 'client/utils/imgix-url';
import { analytics } from 'client/utils/analytics';
import { useTranslation } from 'client/hooks';
import { REG_EXP } from 'client/constants';
import CompanySocials from '../CompanySocials';
import CompanyBanner from '../CompanyBanner';
import css from './CompanyInfo.module.scss';

type CompanyData = Partial<
  Pick<
    NonNullable<Result>,
    | 'name'
    | 'description'
    | 'logo'
    | 'country'
    | 'websiteLink'
    | 'founded'
    | 'socials'
    | 'sourceOfInformation'
    | '_id'
    | 'isInvestBannerVisible'
    | 'mandateId'
  > & {
    companySize?: string;
  }
>;
interface Props {
  className?: string;
  companyInfo?: CompanyData;
  withLogo?: boolean;
  withCompanySize?: boolean;
}

export const CompanyInfo = (props: Props) => {
  const { companyInfo, withLogo = true, withCompanySize = false, className = '' } = props;
  const { translate } = useTranslation();
  const [isShowFullDescription, setIsShowFullDescription] = React.useState(false);

  const isWithSourceInfo =
    companyInfo?.sourceOfInformation &&
    companyInfo?.sourceOfInformation.replace(/\s/g, '').length !== 7 &&
    companyInfo?.sourceOfInformation.replace(/\s/g, '').length !== 9;
  const readMoreClick = () => {
    if (companyInfo?._id) {
      analytics.gtag.event('description_read_more', { ID: companyInfo?._id });
    }

    setIsShowFullDescription(!isShowFullDescription);
  };

  const renderDescription = () => {
    if (companyInfo?.description) {
      const btnText =
        companyInfo?.description.length > 448 && isShowFullDescription
          ? translate('companyPage.readLess')
          : translate('companyPage.readMore');

      const str =
        companyInfo?.description.length > 448 && isShowFullDescription
          ? companyInfo?.description
          : `${companyInfo?.description.slice(0, 448)}...`;
      return (
        <p>
          {companyInfo?.description.length > 448 ? str : companyInfo.description}
          {companyInfo?.description.length > 448 && <span onClick={readMoreClick}>{btnText}</span>}
        </p>
      );
    }
  };

  return (
    <div className={cn(css.companyInfo, className)}>
      <div className={css.title}>
        {withLogo && companyInfo?.logo ? (
          <div className={css.companyLogo}>
            <img src={imgixUrl(companyInfo?.logo, IMGIX_PARAMS.logo)} alt="company-logo" />
          </div>
        ) : null}
        {companyInfo?.name}
      </div>
      <div className={css.description}>{renderDescription()}</div>
      {companyInfo?.isInvestBannerVisible && companyInfo?.name && companyInfo?.mandateId && (
        <CompanyBanner companyName={companyInfo?.name} mandateId={companyInfo?.mandateId} />
      )}
      <div className={css.generalInfo}>
        {companyInfo?.country && (
          <div className={css.row}>
            <div className={css.colLeft}>{translate('companyPage.country')}</div>
            <div className={css.colRight}>{companyInfo?.country}</div>
          </div>
        )}

        {companyInfo?.websiteLink && (
          <div className={css.row}>
            <div className={css.colLeft}>{translate('companyPage.website')}</div>
            <div className={css.colRight}>
              <a
                href={companyInfo?.websiteLink}
                className={css.websiteLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => analytics.gtag.event('website_link', { URL: companyInfo?.websiteLink as string })}
              >
                {companyInfo?.websiteLink.replace(REG_EXP.WEBSITE, '')}
              </a>
            </div>
          </div>
        )}
        {companyInfo?.founded && (
          <div className={css.row}>
            <div className={css.colLeft}>{translate('companyPage.founded')}</div>
            <div className={css.colRight}>{companyInfo?.founded}</div>
          </div>
        )}
        {withCompanySize && companyInfo?.companySize && (
          <div className={css.row}>
            <div className={css.colLeft}>{translate('companyPage.companySize')}</div>
            <div className={css.colRight}>{companyInfo.companySize}</div>
          </div>
        )}
        {companyInfo?.socials && !isRenderSocials(companyInfo.socials) && (
          <div className={css.row}>
            <div className={css.colLeft}>{translate('companyPage.social')}</div>
            <div className={css.colRight}>
              <CompanySocials socials={companyInfo.socials} />
            </div>
          </div>
        )}
      </div>
      {isWithSourceInfo && (
        <div className={css.sourceInfo}>
          {translate('companyPage.sourceInfo')}&nbsp;
          <div
            className={css.sourceInfoWrapper}
            dangerouslySetInnerHTML={{
              __html: companyInfo?.sourceOfInformation ? companyInfo?.sourceOfInformation : '',
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

const IMGIX_PARAMS = {
  bluredBG: {
    'max-w': 1600,
    fit: 'fill',
    auto: 'format,compress',
    q: 40,
  },
  logo: {
    'max-w': 300,
    fit: 'fill',
    auto: 'format,compress',
    q: 85,
  },
  ogImage: {
    'max-w': 1200,
    height: 630,
    fit: 'fill',
    auto: 'format,compress',
    q: 70,
    crop: 'entropy',
  },
};

export const isRenderSocials = (obj: Record<string, string>): boolean => {
  const socialsValues = Object.values(obj) as Array<string>;
  const isEmpty = socialsValues.every((word) => word.trim().length == 0);
  return isEmpty;
};

export default CompanyInfo;
