import React from 'react';
import { format } from 'date-fns';
import cn from 'classnames';

import { CONFIG } from 'constants/index';
import { MANDATES_PAGE_ROUTE, ROOT, USER_PROFILE_INVESTMENTS_ROUTE } from 'client/constants/routes';
import { offer as offerApi } from 'client/redux/api/offer';
import { useTranslation, useResponsive, useAppSelector } from 'client/hooks';
import { analytics } from 'client/utils/analytics';
import { selectUserGAStatus } from 'client/redux/user/selectors';
import { Button, ContentWrap, Icon } from 'client/components/common';

import css from './Investments.module.scss';
import { Link } from 'react-router-dom';
import Loader from 'client/components/common/Loader';

interface Props {
  className?: string;
}

export function Investments(props: Props) {
  const { className = '' } = props;
  const [isMobile] = useResponsive(['MOBILE']);
  const { translate: _t } = useTranslation('userProfile');
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const { data, isLoading } = offerApi.endpoints.paginateOffers.useQuery({ offset: 0, limit: 100 });
  const investments = data?.success ? data?.data.items : [];

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Investor Profile - Investments`,
        page_location: `${CONFIG.domainUrl}${USER_PROFILE_INVESTMENTS_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onViewInvestmentBtnClick = (id: string) => {
    console.log(id);
  };

  return (
    <div className={cn(css.investments, className)}>
      {isLoading ? (
        <Loader position="absolute" spinnerClassname={css.spinner} />
      ) : (
        <section>
          {investments.length > 0 ? (
            <>
              <div className={css.sectionHeader}>
                <ContentWrap className={css.sectionHeaderContent}>
                  <div className={css.titleAndText}>
                    <p className={css.title}>{_t('investor.investments')}</p>
                  </div>
                </ContentWrap>
              </div>
              <div className={css.investmentsList}>
                {investments.map((investment) => {
                  return (
                    <div className={css.investment} key={investment._id}>
                      {isMobile ? (
                        <ContentWrap className={css.investmentContent}>
                          <div className={css.companyTitleWrap}>
                            <div className={css.logoWrap}>
                              <img src={investment.companyLogo} />
                            </div>
                            <div className={css.titleAndValue}>
                              <p className={css.companyTitle}>{investment.companyName}</p>
                              <p className={css.value}>
                                ${` `}
                                {investment.value.toLocaleString()}
                              </p>
                            </div>
                          </div>
                          <div className={css.statusWrap}>
                            <p className={css.date}>{format(new Date(investment.createdAt), 'dd/MM/yy')}</p>
                            <p className={cn(css.status, css[investment.status])}>
                              {_t(`investor.investmentStatus_${investment.status}`)}
                            </p>
                          </div>
                          {false && (
                            <Button className={css.actionBtn} onClick={() => onViewInvestmentBtnClick(investment._id)}>
                              <Icon type="eye" className={css.iconEye} />
                            </Button>
                          )}
                        </ContentWrap>
                      ) : (
                        <ContentWrap className={css.investmentContent}>
                          <div className={css.companyTitleWrap}>
                            <img src={investment.companyLogo} />
                            <p className={css.companyTitle}>{investment.companyName}</p>
                          </div>
                          <p className={css.value}>
                            ${` `}
                            {investment.value.toLocaleString()}
                          </p>
                          <p className={css.date}>{format(new Date(investment.createdAt), 'dd/MM/yy')}</p>
                          <p className={cn(css.status, css[investment.status])}>
                            {_t(`investor.investmentStatus_${investment.status}`)}
                          </p>
                          {false && (
                            <Button className={css.actionBtn} onClick={() => onViewInvestmentBtnClick(investment._id)}>
                              {_t('investor.viewInvestmentBtnLabel')}
                            </Button>
                          )}
                        </ContentWrap>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className={css.noDataMessage}>
              <h6 className={css.title}>{_t('investor.investmentsNoData.title')}</h6>
              <p className={css.message}>{_t('investor.investmentsNoData.message')}</p>
              <Link to={isMobile ? MANDATES_PAGE_ROUTE : ROOT} className={css.button}>
                {_t('investor.investmentsNoData.button')}
              </Link>
            </div>
          )}
        </section>
      )}
    </div>
  );
}
