import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { Result as Mandate } from 'api/mandate/website/get/params';
import { Button, RestrictedBlock } from 'client/components/common';
import { numberToCurrency } from 'client/utils/number-to-currency';
import { MANDATE_RAISED_FIELD_OPTIONS } from 'constants/index';
import { useTranslation, useAppDispatch } from 'client/hooks';
import { selectUserRole } from 'client/redux/user/selectors';
import { setPopup } from 'client/redux/ui';

import { mandate as dummyData } from '../dummy-data';

import css from './TargetInfoMobile.module.scss';

type Props = {
  className?: string;
  mandate: NonNullable<Mandate['mandate']>;
  onOfferClick: () => void;
};

export const TargetInfoMobile = (props: Props) => {
  const { mandate, onOfferClick } = props;
  const dispatch = useAppDispatch();
  const userRole = useSelector(selectUserRole);
  const t = useTranslation().createPrefixedTranslationFunc('mandate');
  const [isDetailOpened, setDetailsOpened] = React.useState(false);

  const renderInvestmentDetails = (props: {
    target: number | string;
    raised: number | string;
    raisedFieldLabel?: string;
    valuation: number | string;
    progress: number;
    seriesFunding: string;
    isProgressVisible: boolean;
  }) => {
    const raisedFieldTitle =
      MANDATE_RAISED_FIELD_OPTIONS.find((m) => m.value === props.raisedFieldLabel)?.label ||
      MANDATE_RAISED_FIELD_OPTIONS[0].label;
    return (
      <div className={css.investmentDetails}>
        <div className={css.investmentDetailsBlock}>
          {props.isProgressVisible && (
            <div className={css.progressWrap}>
              <div className={css.progress} style={{ width: `${props.progress * 100}%` }} />
            </div>
          )}
          <div className={css.values}>
            <div className={css.target}>
              {typeof props.target === 'number'
                ? `${t('investmentTarget')} ${numberToCurrency(props.target)}`
                : props.target}
            </div>
            <div className={css.raised}>
              {typeof props.raised === 'number'
                ? `${raisedFieldTitle} ${numberToCurrency(props.raised)}`
                : props.raised}
            </div>
          </div>
        </div>
        {(props.seriesFunding || props.valuation) && (
          <div className={css.investmentDetailsBlock}>
            <div className={css.round}>
              {props.seriesFunding && <div className={css.roundTitle}>{props.seriesFunding}</div>}
              {props.valuation && (
                <div className={css.roundValuation}>
                  {typeof props.valuation === 'number'
                    ? `${numberToCurrency(props.valuation)} ${t('investmentValuation')}`
                    : props.valuation}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderShortInvestmentDetails = (props: {
    target: number | string;
    raised: number | string;
    raisedFieldLabel?: string;
    valuation: number | string;
    progress: number;
    isProgressVisible: boolean;
  }) => {
    const raisedFieldTitle =
      MANDATE_RAISED_FIELD_OPTIONS.find((m) => m.value === props.raisedFieldLabel)?.label ||
      MANDATE_RAISED_FIELD_OPTIONS[0].label;
    return (
      <div className={css.shortInvestmentDetails}>
        <div className={css.shortInvestmentDetailsBlock}>
          <div className={css.values}>
            <div className={css.valuesInfo}>
              <div className={css.target}>
                {typeof props.target === 'number'
                  ? `${t('investmentTarget')} ${numberToCurrency(props.target)}`
                  : props.target}
              </div>
              <div className={css.raised}>
                {typeof props.raised === 'number'
                  ? `${raisedFieldTitle} ${numberToCurrency(props.raised)}`
                  : props.raised}
              </div>
            </div>
            {props.isProgressVisible && (
              <div className={css.progressWrap}>
                <div className={css.progress} style={{ width: `${props.progress * 100}%` }} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={cn(css.targetInfo, isDetailOpened && css.opened)}>
      <div
        className={cn(css.heightTrigger, isDetailOpened && css.isOpened)}
        onClick={() => setDetailsOpened(!isDetailOpened)}
      />
      <div className={css.content}>
        <RestrictedBlock
          className={css.details}
          fields={[
            'progressBarPercentage',
            'raised',
            'seriesFunding',
            'recruitmentTotalAmount',
            'valuation',
            'prevRounds',
            'isProgressBarVisible',
          ]}
          fallback={renderInvestmentDetails({
            progress: (dummyData.progressBarPercentage ?? 0) / 100,
            raised: dummyData.raised,
            raisedFieldLabel: mandate.raisedFieldLabel,
            seriesFunding: dummyData.seriesFunding ?? '',
            target: dummyData.recruitmentTotalAmount,
            valuation: dummyData.valuation,
            isProgressVisible: dummyData.isProgressBarVisible,
          })}
          onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
        >
          {renderInvestmentDetails({
            progress: (mandate.progressBarPercentage ?? 0) / 100,
            raised: +(mandate.raised ?? 0),
            raisedFieldLabel: mandate.raisedFieldLabel,
            seriesFunding: mandate.seriesFunding ?? '',
            target: +(mandate.recruitmentTotalAmount ?? 0),
            valuation: +(mandate.valuation ?? 0),
            isProgressVisible: !!mandate.isProgressBarVisible,
          })}
        </RestrictedBlock>

        <div className={css.shortView}>
          {isDetailOpened ? (
            <div>
              {['VIEWER', 'INVESTOR_LEVEL_1'].includes(userRole) ? (
                <div className={css.restricted}>{dummyData.termsOfDeal}</div>
              ) : (
                <>
                  {mandate.termsOfDeal && (
                    <div className={css.termsOfDeal}>
                      <p>{t('termsOfDeal')}</p>
                      <div className={css.termsOfDealIcon}>
                        <div className={css.termsOfDealTooltip}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: mandate.termsOfDeal,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <RestrictedBlock
              className={css.shortDetails}
              fields={[
                'progressBarPercentage',
                'raised',
                'recruitmentTotalAmount',
                'valuation',
                'prevRounds',
                'isProgressBarVisible',
              ]}
              fallback={renderShortInvestmentDetails({
                progress: (dummyData.progressBarPercentage ?? 0) / 100,
                raised: dummyData.raised,
                raisedFieldLabel: mandate.raisedFieldLabel,
                target: dummyData.recruitmentTotalAmount,
                valuation: dummyData.valuation,
                isProgressVisible: dummyData.isProgressBarVisible,
              })}
              onUnlockBtnClick={() => dispatch(setPopup({ type: 'accreditation-questionnaire' }))}
            >
              {renderShortInvestmentDetails({
                progress: (mandate.progressBarPercentage ?? 0) / 100,
                raised: +(mandate.raised ?? 0),
                raisedFieldLabel: mandate.raisedFieldLabel,
                target: +(mandate.recruitmentTotalAmount ?? 0),
                valuation: +(mandate.valuation ?? 0),
                isProgressVisible: !!mandate.isProgressBarVisible,
              })}
            </RestrictedBlock>
          )}
          <Button className={css.placeOfferBtn} onClick={onOfferClick}>
            {t('placeAnOffer')}
          </Button>
        </div>
      </div>
    </div>
  );
};
