import React from 'react';
import cn from 'classnames';

import { CONFIG } from 'constants/index';
import * as routes from 'client/constants/routes';
import { useAppSelector, useTranslation, useAppDispatch } from 'client/hooks';
import { setPopup } from 'client/redux/ui';
import { favoriteList as favoriteListApi } from 'client/redux/api/favorite-list';
import { Loader } from 'client/components/common';
import {
  selectAuth,
  selectUserAuthProvider,
  selectUserGAStatus,
  selectUserName,
  selectUserPictureUrl,
} from 'client/redux/user/selectors';
import defaultAvatar from 'client/assets/default-avatar.svg';
import { analytics } from 'client/utils/analytics';

import { Item } from './Item';
import { AvatarUploader } from './AvatarUploader';

import css from './UserProfile.module.scss';

interface Props {
  className?: string;
}

export const GeneralProfile: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const auth = useAppSelector(selectAuth);
  const userName = useAppSelector(selectUserName);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const dispatch = useAppDispatch();
  const avatar = useAppSelector(selectUserPictureUrl);
  const authProvider = useAppSelector(selectUserAuthProvider);
  const { translate: _t } = useTranslation('userProfile');
  const { data, isLoading } = favoriteListApi.endpoints.getFavoriteList.useQuery(undefined, { skip: !auth.isLoggedIn });
  const [deleteCompany, result] = favoriteListApi.endpoints.deleteFromFavoriteList.useMutation();
  const favoriteCompanies = React.useMemo(() => (data?.success ? data.data : []), [data]);

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Profile Page - Viewer`,
        page_location: `${CONFIG.domainUrl}${routes.USER_PROFILE_ROUTE}`,
      });
    }
  }, [userGAStatus]);

  const onCompanyDeleteClick = (id: string) => {
    const text = _t('confirmRemovingLabel');

    if (confirm(text) == true) {
      deleteCompany({ companyId: id });
    }
  };

  const onEditNameClick = () => {
    dispatch(setPopup({ type: 'profile-edit', data: { view: 'edit-name', value: userName } }));
  };

  const onEditPasswordClick = () => {
    dispatch(setPopup({ type: 'profile-edit', data: { view: 'edit-password' } }));
  };

  return (
    <div className={cn(css.userProfile, className)}>
      <div className={css.blurSection} style={{ backgroundImage: `url(${avatar || defaultAvatar})` }}></div>
      <div className={css.editContent}>
        <AvatarUploader imageSrc={avatar || defaultAvatar} />
        <div className={css.info}>
          <div className={css.infoItem}>
            <div>{userName}</div>
            <button onClick={onEditNameClick} className={css.editInfoBtn}>
              {_t('editBtnLabel')}
            </button>
          </div>

          {authProvider === 'emailAndPassword' && (
            <div className={css.infoItem}>
              <div>********</div>
              <button onClick={onEditPasswordClick} className={css.editInfoBtn}>
                {_t('editBtnLabel')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={css.divider} />

      <div className={cn(css.favoriteList, result.isLoading && css.isBusy)}>
        {favoriteCompanies.length !== 0 && userName && (
          <div className={css.title}>{_t('favoriteListTitle').replace('{name}', userName.split(' ')[0])}</div>
        )}
        {isLoading ? (
          <Loader position="relative" />
        ) : (
          <div className={css.items}>
            {favoriteCompanies.map((company) => (
              <Item data={company} key={company._id} onDeleteBtnClick={onCompanyDeleteClick} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralProfile;
